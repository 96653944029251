import React, { useEffect } from "react";
import { StyleSheet, Image, View, useWindowDimensions } from "react-native";
import { useRoute } from "@react-navigation/native";
import {
  ScreenContainer,
  Text,
  Content,
  BackButton,
  Spacing,
  CollapsableSection,
} from "../components";
import { getLayout, relSize } from "../theme";

const About = () => {
  const { width: screenWidth } = useWindowDimensions();
  const { isMobile } = getLayout(screenWidth);
  const route = useRoute();

  useEffect(() => {
    route.params?.toggleUpBtn?.(false);
    return () => {
      route.params?.toggleUpBtn?.(true);
    };
  }, []);

  const rel = (s: number, min?: number, max?: number) =>
    relSize({ screenWidth, size: s, min, max });

  const BasicText = ({ children }: { children: string }) => (
    <Text
      small={isMobile}
      textStyle={!isMobile && { fontSize: rel(30), lineHeight: rel(40) }}
    >
      {children}
    </Text>
  );

  const renderContent = () => (
    <>
      <BasicText>
        {
          "Hi! I’m Hilla, a Turku-based Graphic Designer (BA) and Art Director with 9 years of wide-ranging experience. Companies I’ve previously worked for include Drama Queen (former known as Satumaa) and Pentagon Design, where I’ve worked with big Finnish and international clients."
        }
      </BasicText>
      <CollapsableSection title="Education">
        <View style={styles.section}>
          <Text small bold>
            Bachelor of Culture and Arts
          </Text>
          <Text small>
            {`8 / 2010 - 3 / 2015\nSavonia UAS, Kuopio Academy of Design, graphic design`}
          </Text>
          <Spacing />
          <Text
            small
          >{`9 / 2011 - 12 / 2012\nSavonia UAS, business economics\nProject-oriented business studies (parallel studies)`}</Text>
          <Spacing />
          <Text small bold>
            Graduate
          </Text>
          <Text small>
            {`8 / 2005 - 5 / 2008\nNaantali Upper Secondary School`}
          </Text>
        </View>
      </CollapsableSection>
      <CollapsableSection title="Experience">
        <View style={styles.section}>
          <Text small>
            <Text small bold>
              Packaging & Graphic Designer
            </Text>
            {` at Martinex Oy`}
          </Text>
          <Text small>{`8 / 2023 -`}</Text>
          <Spacing />
          <Text small bold>
            Freelance Graphic Designer
          </Text>
          <Text small>{`1 / 2022 -`}</Text>
          <Spacing />
          <Text small>
            <Text small bold>
              Art Director
            </Text>
            {` at Grundlage`}
          </Text>
          <Text
            small
          >{`9 / 2021 - 12 / 2021\nMultifaceted work as Designer.`}</Text>
          <Spacing />
          <Text small>
            <Text small bold>
              Art Director
            </Text>
            {` at Infinity Design`}
          </Text>
          <Text
            small
          >{`3 / 2019 - 9 / 2021\nMultifaceted work as Designer.`}</Text>
          <Spacing />
          <Text small>
            <Text small bold>
              Graphic Designer
            </Text>
            {` at Pentagon Design`}
          </Text>
          <Text small>{`10 / 2017 - 3 / 2019\nMostly package design.`}</Text>
          <Spacing />
          <Text small>
            <Text small bold>
              Designer
            </Text>
            {` at Satumaa Family Business`}
          </Text>
          <Text small>
            {`5 / 2016 - 6 / 2017\nMultifaceted work as Production Artist in the L’Oréal Nordic team: designing and implementing store materials, designing and producing print-ready packaging and print materials, as well as designing and implementing digital materials (e.g. banners). L’Oréal Group includes brands such as Maybelline, Garnier and Essie. `}
          </Text>
          <Spacing />
          <Text small>
            <Text small bold>
              Graphic Designer
            </Text>
            {` at OS/G Communications`}
          </Text>
          <Text small>
            {`4 / 2015 - 4 / 2016\nLaying out client magazines, laying out and implementing print-ready advertisements and brochures, as well as creating illustrations. `}
          </Text>
          <Spacing />
          <Text small>
            <Text small bold>
              Production Artist
            </Text>
            {` at Briiffi Ltd.`}
          </Text>
          <Text small>9 / 2015 - 12 / 2015</Text>
          <Spacing />
          <Text small>
            <Text small bold>
              Graphic Designer/Marketing Assistant
            </Text>
            {` at Valmet Ltd.`}
          </Text>
          <Text small>{`4 / 2015 - 8 / 2015`}</Text>
          <Spacing />
          <Text small>
            <Text small bold>
              Freelance Production Artist
            </Text>
            {` at OS/G MarCom Ltd.`}
          </Text>
          <Text small>{`2 / 2015 - 3 / 2015`}</Text>
        </View>
      </CollapsableSection>
      <CollapsableSection title="Awards">
        <View style={styles.section}>
          <Text small bold>
            Print shortlist nominee
          </Text>
          <Text small>{`5 / 2017\nYoung Lions Finland 2017`}</Text>
          <Spacing />
          <Text small bold>
            Honorable mention
          </Text>
          <Text small>{`1 / 2018\nJuniors / Vuoden mainostorstai`}</Text>
        </View>
      </CollapsableSection>
      <Spacing />
    </>
  );

  if (isMobile) {
    return (
      <ScreenContainer pageTitle="About">
        <Content>
          <BackButton />
          <Spacing size={30} />
          <Image
            source={require(`../../assets/about.webp`)}
            style={styles.image}
          />
          <Spacing size={35} />
          {renderContent()}
        </Content>
      </ScreenContainer>
    );
  }

  return (
    <ScreenContainer pageTitle="About">
      <View style={styles.content}>
        <View
          style={{
            width: rel(720),
          }}
        >
          <Image
            source={require(`../../assets/about.webp`)}
            style={{
              width: rel(478),
              height: rel(553),
              marginLeft: rel(153),
            }}
          />
        </View>
        <View style={styles.text}>
          <View style={[{ maxWidth: rel(933) }]}>{renderContent()}</View>
        </View>
      </View>
      <Spacing size={screenWidth * 0.1} />
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  image: {
    width: 250,
    height: 250,
  },
  section: {
    paddingVertical: 26,
  },
  content: {
    flex: 1,
    flexDirection: "row",
  },
  text: {
    justifyContent: "flex-start",
  },
});

export default About;
