import React from "react";
import { View, StyleSheet, Image, useWindowDimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  ScreenContainer,
  Content,
  BackButton,
  Spacing,
  Text,
  BrandColors,
  ProjectDescription,
} from "../components";
import { getLayout, relSize, appStyles } from "../theme";

const Fazer = () => {
  const { width: screenWidth } = useWindowDimensions();
  const { size, isMobile } = getLayout(screenWidth);
  const { goBack, navigate } = useNavigation();

  /* @ts-ignore */
  const onNextPress = () => navigate("Aland");

  const rel = (s: number, min?: number, max?: number) =>
    relSize({ screenWidth, size: s, min, max });

  if (isMobile) {
    return (
      <ScreenContainer
        pageTitle="Fazer packaging compilation"
        onNextPress={onNextPress}
        onBackPress={goBack}
      >
        <Content>
          <BackButton />
          <Spacing />
        </Content>
        <Image
          source={require(`../../assets/fazer2${size}.webp`)}
          style={{ width: screenWidth, height: screenWidth }}
        />
        <Spacing size={30} />
        <Content>
          <Text small>LIQUORICE: Pattern & Color Design / Art Work</Text>
        </Content>
        <Image
          source={require("../../assets/fazer3.svg")}
          style={{ width: screenWidth, height: screenWidth }}
        />
        <Image
          source={require(`../../assets/fazer1${size}.webp`)}
          style={{ width: screenWidth, height: screenWidth }}
        />
        <View style={[styles.smallImg, styles.marginView]}>
          <Image
            source={require("../../assets/fazer4.webp")}
            style={{ width: screenWidth * 0.7, height: screenWidth * 0.8 }}
          />
        </View>
        <Spacing size={70} />
        <Content>
          <Text small>
            HANDMADE PRALINES: Concept / Pattern Design (final work by
            Juha-Tuomas Reinikainen)
          </Text>
        </Content>
        <Spacing size={50} />
        <Image
          source={require("../../assets/fazer5.svg")}
          style={{ width: screenWidth, height: screenWidth, marginBottom: -20 }}
        />
        <BrandColors gold colors={["#9E7B37", "#FFF"]} />
        <View style={[styles.smallImg, styles.marginView]}>
          <Image
            source={require("../../assets/fazer6.webp")}
            style={{ width: screenWidth * 0.7, height: screenWidth * 0.7 }}
          />
        </View>
        <View style={[styles.smallImg, styles.marginView]}>
          <Image
            source={require("../../assets/fazer7.webp")}
            style={{ width: screenWidth, height: screenWidth * 0.6 }}
          />
        </View>
        <View style={[styles.smallImg, styles.marginView, { marginTop: -5 }]}>
          <Image
            source={require("../../assets/fazer8.webp")}
            style={{ width: screenWidth * 0.9, height: screenWidth * 0.8 }}
          />
        </View>
        <View style={[styles.smallImg, { marginTop: -100 }]}>
          <Image
            source={require("../../assets/fazer9.webp")}
            style={{
              width: screenWidth * 0.9,
              height: screenWidth,
              transform: [{ rotate: "-20deg" }],
            }}
          />
        </View>
        <Spacing size={70} />
        <Content>
          <Text small>GEISHA CRISPY: Pattern Design / Art Work</Text>
        </Content>
        <View style={[styles.smallImg, styles.marginView]}>
          <Image
            source={require("../../assets/fazer10.webp")}
            style={{ width: screenWidth, height: screenWidth * 0.8 }}
          />
        </View>
        <View style={[styles.smallImg]}>
          <Image
            source={require("../../assets/fazer11.webp")}
            style={{
              width: screenWidth,
              height: screenWidth,
            }}
          />
        </View>
        <Spacing size={70} />
        <Content>
          <Text small>DOMINO: Illustrations / Art Work</Text>
        </Content>
        <View style={[styles.smallImg, styles.marginView]}>
          <Image
            source={require("../../assets/fazer12.webp")}
            style={{ width: screenWidth, height: screenWidth }}
          />
        </View>
        <View style={[styles.smallImg]}>
          <Image
            source={require("../../assets/fazer13.webp")}
            style={{
              width: screenWidth,
              height: screenWidth,
            }}
          />
        </View>
        <Spacing />
      </ScreenContainer>
    );
  }

  return (
    <ScreenContainer
      pageTitle="Fazer packaging compilation"
      onNextPress={onNextPress}
      onBackPress={goBack}
    >
      <View style={appStyles(screenWidth).webHeader}>
        <Image
          resizeMode="contain"
          source={require(`../../assets/fazer2${size}.webp`)}
          style={{
            width: rel(1190),
            height: rel(904),
          }}
        />
        <ProjectDescription
          client="Fazer"
          role="Graphic Designer in Pattern & Colour Design / Art Work"
        />
      </View>
      <Image
        source={require("../../assets/fazer3.svg")}
        style={{ width: screenWidth, height: rel(650), zIndex: 9 }}
      />
      <View style={styles.row}>
        <Image
          source={require(`../../assets/fazer1${size}.webp`)}
          style={{
            width: rel(975),
            height: rel(1285),
            marginTop: -rel(320),
            marginLeft: rel(125),
          }}
        />
        <Image
          source={require("../../assets/fazer4.webp")}
          style={{
            width: rel(560),
            height: rel(748),
            marginLeft: rel(90),
            alignSelf: "flex-end",
          }}
        />
      </View>
      <Spacing size={rel(195)} />
      <View style={{ marginLeft: rel(142), maxWidth: rel(600) }}>
        <Text webSmall light>
          <Text webSmall bold>
            ROLE
          </Text>
          {` AD & Graphic Designer in Concept / Pattern Design (final work by Juha-Tuomas Reinikainen)`}
        </Text>
      </View>
      <Spacing size={rel(70)} />
      <View style={styles.row}>
        <Image
          source={require("../../assets/fazer5.svg")}
          style={{ width: rel(893), height: rel(893) }}
        />

        <Spacing vertical size={rel(90)} />
        <BrandColors web gold colors={["#9E7B37", "#FFF"]} />
        <Spacing vertical size={rel(90)} />
        <Image
          source={require("../../assets/fazer6.webp")}
          style={{ width: rel(560), height: rel(648) }}
        />
      </View>
      <View style={styles.row}>
        <Image
          source={require("../../assets/fazer7.webp")}
          style={{
            width: rel(704),
            height: rel(630),
            marginLeft: rel(140),
            marginTop: -rel(120),
          }}
        />
        <Image
          source={require("../../assets/fazer9.webp")}
          style={{
            width: rel(670),
            height: rel(696),
            marginLeft: -rel(120),
            marginTop: -rel(200),
            transform: [{ rotate: "-10deg" }],
          }}
        />
        <Image
          source={require("../../assets/fazer8.webp")}
          style={{
            width: rel(625),
            height: rel(686),
            marginLeft: -rel(220),
            marginTop: -rel(280),
          }}
        />
      </View>
      <Spacing size={rel(240)} />
      <View style={{ marginLeft: rel(192), maxWidth: rel(600) }}>
        <Text webSmall light>
          <Text webSmall bold>
            ROLE
          </Text>
          {` Graphic Designer in Pattern Design / Art Work`}
        </Text>
      </View>
      <Spacing size={rel(55)} />
      <View style={styles.row}>
        <Image
          source={require("../../assets/fazer10.webp")}
          style={{
            width: rel(717),
            height: rel(538),
            marginLeft: rel(192),
          }}
        />
        <Image
          source={require("../../assets/fazer11.webp")}
          style={{
            width: rel(756),
            height: rel(1008),
            marginLeft: rel(91),
          }}
        />
      </View>
      <Spacing size={rel(50)} />
      <View style={{ marginLeft: rel(192), maxWidth: rel(600) }}>
        <Text webSmall light>
          <Text webSmall bold>
            ROLE
          </Text>
          {` Graphic Designer in Illustrations / Art Work`}
        </Text>
      </View>
      <Spacing size={rel(55)} />
      <View style={styles.row}>
        <Image
          source={require("../../assets/fazer12.webp")}
          style={{
            width: rel(808),
            height: rel(926),
            marginLeft: rel(192),
          }}
        />
        <Image
          source={require("../../assets/fazer13.webp")}
          style={{
            width: rel(662),
            height: rel(756),
            marginLeft: rel(94),
            alignSelf: "flex-end",
          }}
        />
      </View>
      <Spacing size={rel(360)} />
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  smallImg: {
    justifyContent: "center",
    alignItems: "center",
  },
  marginView: {
    marginTop: 40,
  },
  row: {
    flexDirection: "row",
  },
});

export default Fazer;
