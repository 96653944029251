import React, { useState } from "react";
import {
  View,
  StyleSheet,
  useWindowDimensions,
  TouchableOpacity,
} from "react-native";
import { EvilIcons } from "@expo/vector-icons";
import Collapsible from "react-native-collapsible";
import Text from "./Text";
import MenuButton from "./MenuButton";
import BackButton from "./BackButton";
import { colors, sizes, getLayout, relSize } from "../theme";
import { useNavigation } from "@react-navigation/native";
import { Spacing } from ".";

interface HeaderProps {
  title: string;
  hideBackBtn?: boolean;
  hideNav?: boolean;
}

const Header = ({ title, hideBackBtn, hideNav }: HeaderProps) => {
  const { width: screenWidth } = useWindowDimensions();
  const { navigate } = useNavigation();
  const { isMobile } = getLayout(screenWidth);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const rel = (s: number, min?: number, max?: number) =>
    relSize({ screenWidth, size: s, min, max });

  const onNavButtonPress = (routeName: "Home" | "About" | "Contact") => {
    /* @ts-ignore */
    navigate(routeName);
    setMenuOpen(false);
  };

  if (!isMobile) {
    return (
      <View style={[styles.webHeader, { paddingHorizontal: rel(153) }]}>
        <Text capitalize web>
          {title}
        </Text>
        {title !== "Hilla Kouki" && !hideBackBtn && <BackButton />}
      </View>
    );
  }

  return (
    <View
      style={{ position: "absolute", top: 0, right: 0, left: 0, zIndex: 9999 }}
    >
      {!menuOpen && (
        <View style={styles.closed}>
          <Text capitalize>{title}</Text>
          {!hideNav && <MenuButton onPress={() => setMenuOpen(true)} />}
        </View>
      )}
      <Collapsible
        collapsed={!menuOpen}
        collapsedHeight={0}
        style={{ backgroundColor: colors.bg }}
      >
        <View style={styles.open}>
          <TouchableOpacity onPress={() => onNavButtonPress("Home")}>
            <Text>Work</Text>
          </TouchableOpacity>
          <Spacing size={14} />
          <TouchableOpacity onPress={() => onNavButtonPress("About")}>
            <Text>About</Text>
          </TouchableOpacity>
          <Spacing size={14} />
          <TouchableOpacity onPress={() => onNavButtonPress("Contact")}>
            <Text>Contact</Text>
          </TouchableOpacity>
          <Spacing size={20} />
          <TouchableOpacity onPress={() => setMenuOpen(false)}>
            <EvilIcons name="close" size={60} color={colors.text} />
          </TouchableOpacity>
        </View>
      </Collapsible>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: 9,
    minWidth: 320,
    paddingHorizontal: sizes.margin,
    paddingVertical: 45,
    backgroundColor: colors.bg,
  },
  webHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 67,
    backgroundColor: colors.bg,
  },
  closed: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    paddingTop: 45,
    paddingHorizontal: 32,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  open: {
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 55,
    paddingBottom: 60,
  },
});

export default Header;
