import React from "react";
import { View, StyleSheet, useWindowDimensions } from "react-native";
import { Spacing } from ".";
import { relSize } from "../theme";

interface BrandColorsProps {
  colors: string[];
  minorColors?: string[];
  equalSize?: boolean;
  gold?: boolean;
  web?: boolean;
}

const BrandColors = ({
  colors,
  minorColors,
  equalSize,
  gold,
  web,
}: BrandColorsProps) => {
  const { width: screenWidth } = useWindowDimensions();

  const rel = (s: number, min?: number, max?: number) =>
    relSize({ screenWidth, size: s, min, max });

  const renderBasic = (colorList: string[]) => (
    <View style={[!web && styles.colorContainer]}>
      {colorList.map((color, index) => {
        const isLast = index === colors.length - 1;
        return (
          <View
            key={`brandcolor_${color}`}
            style={[
              web
                ? {
                    height: rel(120),
                    width: rel(120),
                    borderRadius: rel(60),
                  }
                : styles.color,
              { backgroundColor: color },
              !isLast && !web && styles.marginSmall,
              (!isLast || equalSize) &&
                web && {
                  marginBottom: rel(32),
                },
              ["#FFF", "#FFFAD9", "#DDE2E4"].includes(color) && styles.border,
              gold && styles.gold,
            ]}
          />
        );
      })}
    </View>
  );
  const renderSecondRow = () => {
    if (!minorColors?.length) return null;

    if (equalSize) {
      return (
        <>
          <Spacing size={web ? rel(32) : 15} vertical={web} />
          {renderBasic(minorColors)}
        </>
      );
    }

    return (
      <View
        style={[
          web
            ? {
                marginLeft: rel(40),
                alignItems: "center",
              }
            : styles.minorContainer,
        ]}
      >
        {minorColors.map((color, index) => {
          const isLast = index === minorColors.length - 1;
          const basicStyle = web
            ? {
                marginBottom: rel(32),
              }
            : styles.margin;
          const lastStyle = web
            ? {
                width: rel(42),
                height: rel(42),
                borderRadius: rel(21),
              }
            : styles.tiny;
          return (
            <View
              key={`minorcolor_${color}`}
              style={[
                web
                  ? {
                      height: rel(84),
                      width: rel(84),
                      borderRadius: rel(42),
                    }
                  : styles.minorColor,
                { backgroundColor: color },
                isLast ? lastStyle : basicStyle,
                ["#FFF", "#FFFAD9"].includes(color) && styles.border,
              ]}
            />
          );
        })}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={[web && styles.colorContainer]}>
        <View style={styles.colorContainer}>{renderBasic(colors)}</View>
        {renderSecondRow()}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  colorContainer: {
    flexDirection: "row",
  },
  minorContainer: {
    marginTop: 16,
    paddingHorizontal: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  color: {
    height: 60,
    width: 60,
    borderRadius: 30,
  },
  margin: {
    marginRight: 16,
  },
  marginSmall: {
    marginRight: 14,
  },
  border: {
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.3)",
  },
  minorColor: {
    height: 40,
    width: 40,
    borderRadius: 20,
  },
  tiny: {
    width: 20,
    height: 20,
    borderRadius: 10,
  },
  gold: {
    borderColor: "#9E7B37",
  },
});

export default BrandColors;
