import React, { ReactNode } from "react";
import {
  Text as RNText,
  StyleSheet,
  useWindowDimensions,
  StyleProp,
  TextStyle,
} from "react-native";
import { colors, relSize } from "../theme";

interface TextProps {
  children: string | ReactNode;
  bold?: boolean;
  light?: boolean;
  capitalize?: boolean;
  underlined?: boolean;
  small?: boolean;
  webSmall?: boolean;
  medium?: boolean;
  fontSize?: number | null;
  web?: boolean;
  upLabel?: boolean;
  textStyle?: StyleProp<TextStyle>;
}

const Text = ({
  children,
  bold,
  light,
  capitalize,
  underlined,
  small,
  webSmall,
  medium,
  fontSize,
  web,
  upLabel,
  textStyle,
}: TextProps) => {
  const { width } = useWindowDimensions();

  const rel = (s: number, min?: number, max?: number) =>
    relSize({ screenWidth: width, size: s, min, max });

  return (
    <RNText
      style={[
        styles.text,
        web && {
          fontSize: rel(35, 25),
          lineHeight: rel(50, 40),
        },
        bold && styles.bold,
        light && styles.light,
        capitalize && styles.caps,
        small && styles.small,
        webSmall && {
          fontSize: rel(25),
          lineHeight: rel(40, 30),
        },
        medium && styles.medium,
        !!fontSize && { fontSize: rel(fontSize) },
        underlined && styles.underlined,
        upLabel && {
          fontSize: rel(20),
          lineHeight: rel(28),
          letterSpacing: 0.8,
        },
        textStyle,
      ]}
    >
      {children}
    </RNText>
  );
};

const styles = StyleSheet.create({
  text: {
    color: colors.text,
    fontFamily: "BasicRegular",
    fontSize: 30,
    lineHeight: 30,
  },
  caps: {
    textTransform: "uppercase",
    letterSpacing: 1.4,
  },
  bold: {
    fontFamily: "BasicRegular",
    fontWeight: "bold",
  },
  small: {
    fontSize: 20,
  },
  medium: {
    fontSize: 25,
  },
  underlined: {
    textDecorationLine: "underline",
  },
  light: {
    fontFamily: "BasicLight",
  },
});

export default Text;
