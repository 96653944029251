import React, { useState, useCallback, useContext } from "react";
import {
  View,
  StyleSheet,
  useWindowDimensions,
  TextInput,
  TouchableOpacity,
} from "react-native";
import { ScreenContainer, Text } from "../components";
import { colors, relSize, getLayout } from "../theme";
import { LoginContext } from "../../App";

const Login = () => {
  const { width: screenWidth } = useWindowDimensions();
  const { setLoggedIn } = useContext(LoginContext);
  const { isMobile } = getLayout(screenWidth);

  const [value, setValue] = useState<string>("");

  const rel = (s: number, min?: number, max?: number) =>
    relSize({ screenWidth, size: s, min, max });

  const onSubmit = useCallback(() => {
    if (value === "hillaemilia22") {
      setLoggedIn(true);
    }
  }, [value]);

  if (isMobile) {
    return (
      <ScreenContainer hideNav pageTitle="Hilla Kouki's portfolio">
        <View
          style={{
            height: "25vh",
            justifyContent: "center",
            marginHorizontal: 32,
          }}
        >
          <Text textStyle={{ fontSize: 25 }}>Password</Text>
          <View style={{ flexDirection: "row", marginTop: 17 }}>
            <TextInput
              value={value}
              onChangeText={setValue}
              style={[
                styles.input,
                { fontSize: 20, height: 35, width: "55vw" },
              ]}
              onSubmitEditing={onSubmit}
              secureTextEntry
            />
            <TouchableOpacity
              onPress={onSubmit}
              style={[
                styles.button,
                {
                  height: 35,
                  marginLeft: 11,
                  paddingHorizontal: 20,
                },
              ]}
            >
              <Text
                textStyle={[
                  styles.buttonLabel,
                  { fontSize: 15, letterSpacing: 0.9 },
                ]}
              >
                LOGIN
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScreenContainer>
    );
  }

  return (
    <ScreenContainer pageTitle="Hilla Kouki's portfolio" hideBackBtn>
      <View style={styles.content}>
        <View style={[styles.inputContainer]}>
          <Text textStyle={{ fontSize: rel(35) }}>Password</Text>
          <TextInput
            value={value}
            onChangeText={setValue}
            style={[
              styles.input,
              { marginLeft: rel(20), height: rel(45, 35), width: rel(450) },
            ]}
            onSubmitEditing={onSubmit}
            secureTextEntry
          />
          <TouchableOpacity
            onPress={onSubmit}
            style={[
              styles.button,
              {
                height: rel(45, 35),
                marginLeft: rel(20),
                paddingHorizontal: rel(26),
              },
            ]}
          >
            <Text textStyle={[styles.buttonLabel, { fontSize: rel(20) }]}>
              LOGIN
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  content: {
    height: "80vh",
    justifyContent: "center",
    alignItems: "center",
  },
  inputContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    borderColor: colors.text,
    borderWidth: 3,
    paddingHorizontal: 10,
    fontFamily: "BasicRegular",
    letterSpacing: 2,
    fontSize: 30,
    color: colors.text,
  },
  button: {
    backgroundColor: colors.text,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonLabel: {
    color: colors.bg,
    fontWeight: "bold",
    letterSpacing: 1.2,
  },
});

export default Login;
