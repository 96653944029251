import { Dimensions, StyleSheet } from "react-native";

const defaultWidth = Dimensions.get("window").width;

export const getLayout = (screenWidth?: number | undefined) => {
  const width = screenWidth ?? defaultWidth;
  return { size: width > 1500 ? "@2" : "@1", isMobile: width < 750 };
};

export const getSize = (pixels: number, screenWidth: number) =>
  screenWidth * (pixels / 1920);

export const relSize = ({
  screenWidth,
  size,
  min,
  max,
}: {
  screenWidth: number;
  size: number;
  min?: number;
  max?: number;
}) => {
  const rel = getSize(size, screenWidth);
  if (min && rel < min) return min;
  if (max && rel > max) return max;
  return rel;
};

export const colors = {
  bg: "#FFFAF5",
  text: "#C3B0B0",
};

export const isSmallScreen = (width: number) => width < 360;

export const sizes = {
  margin: 32,
  smallMargin: 26,
};

export const appStyles = (screenWidth: number) =>
  StyleSheet.create({
    webHeader: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    webDesc: {
      flex: 1,
      paddingHorizontal: getSize(130, screenWidth),
    },
    webDescBox: {
      flex: 1,
      maxWidth: getSize(450, screenWidth),
      marginHorizontal: getSize(20, screenWidth),
    },
  });
