import React from "react";
import { View } from "react-native";

interface SpacingProps {
  size?: number;
  vertical?: boolean;
}

const Spacing = ({ size = 26, vertical }: SpacingProps) => (
  <View style={[vertical ? { width: size } : { height: size }]} />
);

export default Spacing;
