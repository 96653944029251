import React, {
  useState,
  useEffect,
  createContext,
  useRef,
  useMemo,
} from "react";
import { Image, ScrollView, useWindowDimensions, View } from "react-native";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as Linking from "expo-linking";
import { useFonts } from "expo-font";
import Home from "./src/pages/Home";
import About from "./src/pages/About";
import Contact from "./src/pages/Contact";
import Aland from "./src/pages/Aland";
import Fincap from "./src/pages/Fincap";
import Makitalo from "./src/pages/Makitalo";
import Kivikyla from "./src/pages/Kivikyla";
import MBakery from "./src/pages/MBakery";
import Fazer from "./src/pages/Fazer";
import Helmikallio from "./src/pages/Helmikallio";
import Login from "./src/pages/Login";
import { getLayout, colors, relSize } from "./src/theme";
import ToTopButton from "./src/components/ToTopButton";

export const LoginContext = createContext({
  loggedIn: false,
  setLoggedIn: (val: boolean) => {},
});

const Stack = createNativeStackNavigator();

const App = () => {
  const { width } = useWindowDimensions();
  const { isMobile } = getLayout(width);
  const scrollRef = useRef<ScrollView>(null);

  const [assetsReady, setAssetsReady] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(true);
  const [upBtnVisible, setUpBtnVisible] = useState<boolean>(false);
  const [hideUpBtnForScreen, setHideUpBtnForScreen] = useState<boolean>(false);

  const [fontsLoaded] = useFonts({
    BasicRegular: require("./assets/fonts/BasicSansRegular.ttf"),
    BasicLight: require("./assets/fonts/BasicSansLight.ttf"),
    GopherMono: require("./assets/fonts/GopherMonoExtraBold.otf"),
    Rubik: require("./assets/fonts/RubikMonoOne.ttf"),
    Henderson: require("./assets/fonts/HendersonSans.ttf"),
    Bodoni: require("./assets/fonts/BodoniModa.ttf"),
    Bevan: require("./assets/fonts/Bevan.ttf"),
    InputSans: require("./assets/fonts/InputSans.ttf"),
    Bely: require("./assets/fonts/Bely.ttf"),
    Ayuthaya: require("./assets/fonts/Ayuthaya.ttf"),
  });

  useEffect(() => {
    prefetchImages().then(() => setAssetsReady(true));
  }, []);

  const rel = (s: number, min?: number, max?: number) =>
    relSize({ screenWidth: width, size: s, min, max });

  const scrollToTop = () =>
    scrollRef.current?.scrollTo?.({ x: 0, y: 0, animated: true });

  const prefetchImages = () => {
    const { size } = getLayout(width);

    const images = [
      require(`./assets/about.webp`),
      require(`./assets/aland1${size}.webp`),
      require(`./assets/aland2.svg`),
      require(`./assets/aland3_1.webp`),
      require(`./assets/aland3_2.webp`),
      require(`./assets/aland3_3.webp`),
      require(`./assets/aland3_4.webp`),
      require(`./assets/aland4${size}.webp`),
      require(`./assets/aland5${size}.webp`),
      require(`./assets/aland6${size}.webp`),
      require(`./assets/aland7${size}.webp`),
      require(`./assets/fazer_logo${size}.webp`),
      require(`./assets/fazer1${size}.webp`),
      require(`./assets/fazer2${size}.webp`),
      require(`./assets/fazer3.svg`),
      require(`./assets/fazer4.webp`),
      require(`./assets/fazer5.svg`),
      require(`./assets/fazer6.webp`),
      require(`./assets/fazer7.webp`),
      require(`./assets/fazer8.webp`),
      require(`./assets/fazer9.webp`),
      require(`./assets/fazer10.webp`),
      require(`./assets/fazer11.webp`),
      require(`./assets/fazer12.webp`),
      require(`./assets/fazer13.webp`),
      require(`./assets/fincap1.svg`),
      require(`./assets/fincap2${size}.webp`),
      require(`./assets/fincap3.svg`),
      require(`./assets/fincap4${size}.webp`),
      require(`./assets/fincap5${size}.webp`),
      require(`./assets/fincap6.webp`),
      require(`./assets/fincap7${size}.webp`),
      require(`./assets/helmikallio1${size}.webp`),
      require(`./assets/helmikallio2${size}.webp`),
      require(`./assets/helmikallio3.svg`),
      require(`./assets/helmikallio4.svg`),
      require(`./assets/helmikallio5${size}.webp`),
      require(`./assets/helmikallio6${size}.webp`),
      require(`./assets/kivikyla1.svg`),
      require(`./assets/kivikyla2${size}.webp`),
      require(`./assets/kivikyla3.svg`),
      require(`./assets/kivikyla4${size}.webp`),
      require(`./assets/kivikyla5${size}.webp`),
      require(`./assets/kivikyla6${size}.webp`),
      require(`./assets/kivikyla7${size}.webp`),
      require(`./assets/kivikyla8${size}.webp`),
      require(`./assets/kivikyla9${size}.webp`),
      require(`./assets/kivikyla10${size}.webp`),
      require(`./assets/kivikyla11${size}.webp`),
      require(`./assets/kivikyla12${size}.webp`),
      require(`./assets/makitalo1${size}.webp`),
      require(`./assets/makitalo2.svg`),
      require(`./assets/makitalo3${size}.webp`),
      require(`./assets/makitalo4${size}.webp`),
      require(`./assets/makitalo5${size}.webp`),
      require(`./assets/makitalo6_1.webp`),
      require(`./assets/makitalo6_2.webp`),
      require(`./assets/makitalo6_3.webp`),
      require(`./assets/makitalo6_4.webp`),
      require(`./assets/makitalo6_5.webp`),
      require(`./assets/makitalo6_6.webp`),
      require(`./assets/makitalo7${size}.webp`),
      require(`./assets/makitalo8${size}.webp`),
      require(`./assets/mbakery1.svg`),
      require(`./assets/mbakery2${size}.webp`),
      require(`./assets/mbakery3.svg`),
      require(`./assets/mbakery4${size}.webp`),
      require(`./assets/mbakery5${size}.webp`),
      require(`./assets/mbakery6${size}.webp`),
      require(`./assets/mbakery7${size}.webp`),
    ];

    const cacheImages = images.map((image) => Image.prefetch(image));
    return Promise.all(cacheImages).then(() => console.log("Hi there!"));
  };

  const prefix = Linking.createURL("/");

  const linking = {
    prefixes: [prefix],
    config: {
      screens: {
        Login: "/login",
        Home: "/",
        About: "/about",
        Contact: "/contact",
        Aland: "/aland",
        Fincap: "/fincap",
        Makitalo: "/makitalo",
        Kivikyla: "/kivikyla",
        Mbakery: "/mbakery",
        Fazer: "/fazer",
        Helmikallio: "/helmikallio",
      },
    },
  };

  const btmButton = useMemo(() => {
    if (!loggedIn || !upBtnVisible || hideUpBtnForScreen) return null;
    return (
      <View
        style={{
          position: "absolute",
          right: isMobile ? 20 : 50,
          bottom: isMobile ? 40 : 70,
        }}
      >
        <ToTopButton
          onPress={scrollToTop}
          style={{
            width: 50,
            height: 50,
            borderRadius: 25,
            borderWidth: 2,
            borderColor: colors.text,
          }}
        />
      </View>
    );
  }, [loggedIn, upBtnVisible, isMobile]);

  const toggleUpBtn = (btnVisible: boolean) => {
    setHideUpBtnForScreen(!btnVisible);
  };

  const onScroll = (event: any) => {
    const yOffset = event.nativeEvent.contentOffset.y;
    if (yOffset === 0) {
      setUpBtnVisible(false);
    } else {
      setUpBtnVisible(true);
    }
  };

  return (
    <SafeAreaProvider>
      <SafeAreaView style={{ flex: 1, backgroundColor: colors.bg }}>
        <ScrollView
          ref={scrollRef}
          onScroll={onScroll}
          scrollEventThrottle={16}
        >
          <NavigationContainer linking={linking}>
            <LoginContext.Provider value={{ loggedIn, setLoggedIn }}>
              <Stack.Navigator
                screenOptions={{ headerShown: false }}
                screenListeners={{
                  state: (e) =>
                    scrollRef.current?.scrollTo({
                      x: 0,
                      y: 0,
                      animated: false,
                    }),
                }}
              >
                {loggedIn /*&& !!fontsLoaded*/ ? (
                  <>
                    <Stack.Screen
                      name="Home"
                      component={Home}
                      options={{ title: "Hilla Kouki" }}
                    />
                    <Stack.Screen
                      name="About"
                      component={About}
                      options={{ title: "Hilla Kouki | About" }}
                      initialParams={{ toggleUpBtn }}
                    />
                    <Stack.Screen
                      name="Contact"
                      component={Contact}
                      options={{ title: "Hilla Kouki | Contact" }}
                      initialParams={{ toggleUpBtn }}
                    />
                    <Stack.Screen
                      name="Aland"
                      component={Aland}
                      options={{ title: "Hilla Kouki | Åland Distillery" }}
                    />
                    <Stack.Screen
                      name="Fincap"
                      component={Fincap}
                      options={{ title: "Hilla Kouki | FINCAP" }}
                    />
                    <Stack.Screen
                      name="Makitalo"
                      component={Makitalo}
                      options={{ title: "Hilla Kouki | Mäkitalon farmi" }}
                    />
                    <Stack.Screen
                      name="Kivikyla"
                      component={Kivikyla}
                      options={{ title: "Hilla Kouki | Kivikylän Kot&Go" }}
                    />
                    <Stack.Screen
                      name="Mbakery"
                      component={MBakery}
                      options={{ title: "Hilla Kouki | MBakery" }}
                    />
                    <Stack.Screen
                      name="Fazer"
                      component={Fazer}
                      options={{ title: "Hilla Kouki | Fazer" }}
                    />
                    <Stack.Screen
                      name="Helmikallio"
                      component={Helmikallio}
                      options={{ title: "Hilla Kouki | Helmikallio" }}
                    />
                  </>
                ) : (
                  <Stack.Screen
                    name="Login"
                    component={Login}
                    options={{ title: "Hilla Kouki's Portfolio" }}
                  />
                )}
              </Stack.Navigator>
            </LoginContext.Provider>
          </NavigationContainer>
        </ScrollView>
        {btmButton}
      </SafeAreaView>
    </SafeAreaProvider>
  );
};

export default App;
