import React from "react";
import { View, StyleSheet, Image, useWindowDimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  ScreenContainer,
  Content,
  BackButton,
  Spacing,
  Text,
  BrandColors,
  Divider,
  FontExamples,
  ProjectDescription,
} from "../components";
import { getLayout, appStyles } from "../theme";

const Fincap = () => {
  const { width: screenWidth } = useWindowDimensions();
  const { size, isMobile } = getLayout(screenWidth);
  const { goBack, navigate } = useNavigation();

  /* @ts-ignore */
  const onNextPress = () => navigate("Helmikallio");

  if (isMobile) {
    return (
      <ScreenContainer
        pageTitle="Fincap"
        onNextPress={onNextPress}
        onBackPress={goBack}
      >
        <Content>
          <BackButton />
          <Spacing />
        </Content>
        <Image
          source={require(`../../assets/fincap2${size}.webp`)}
          style={{ width: screenWidth, height: screenWidth }}
        />
        <Spacing size={30} />
        <Content>
          <Text small>Branding / Identity / Logo Design</Text>
        </Content>
        <Spacing />
        <View style={styles.content}>
          <Image
            resizeMode="cover"
            source={require("../../assets/fincap3.svg")}
            style={styles.logo}
          />

          <BrandColors
            colors={["#CE825E", "#D8D8DA", "#1C1C1C", "#FFF"]}
            minorColors={["#BCD3D8", "#D3E0E2", "#E2CEBF", "#E53D1C"]}
          />
          <Spacing size={40} />
          <FontExamples page="Fincap" />
          <Spacing size={10} />
          <Divider fincap />
          <Image
            resizeMode="cover"
            source={require(`../../assets/fincap4${size}.webp`)}
            style={{ width: screenWidth, height: screenWidth * 0.8 }}
          />
          <Image
            source={require(`../../assets/fincap5${size}.webp`)}
            style={{ width: screenWidth, height: screenWidth * 0.9 }}
          />
          <Image
            source={require("../../assets/fincap6.webp")}
            style={{ width: screenWidth, height: screenWidth * 0.5 }}
          />
          <Image
            source={require(`../../assets/fincap7${size}.webp`)}
            style={{ width: screenWidth, height: screenWidth * 0.7 }}
          />
        </View>
        <Spacing />
      </ScreenContainer>
    );
  }
  return (
    <ScreenContainer
      pageTitle="Fincap"
      onNextPress={onNextPress}
      onBackPress={goBack}
    >
      <View style={appStyles(screenWidth).webHeader}>
        <Image
          resizeMode="contain"
          source={require(`../../assets/fincap2${size}.webp`)}
          style={{
            width: screenWidth * 0.63,
            height: screenWidth * 0.47,
          }}
        />
        <ProjectDescription
          client="FINCAP"
          role="Art Director, Graphic Designer in Branding / Identity / Logo Design"
        />
      </View>
      <View
        style={[
          styles.webBrands,
          { paddingTop: screenWidth * 0.1, paddingRight: screenWidth * 0.08 },
        ]}
      >
        <View style={styles.logoContainer}>
          <Image
            resizeMode="contain"
            source={require("../../assets/fincap3.svg")}
            style={{
              width: screenWidth * 0.4,
              height: screenWidth * 0.4,
              marginTop: -screenWidth * 0.1,
              marginLeft: -screenWidth * 0.02,
              marginRight: -screenWidth * 0.06,
            }}
          />
          <View style={{ marginLeft: screenWidth * 0.02 }}>
            <Divider fincap web />
          </View>
        </View>
        <FontExamples web page="Fincap" />
        <View>
          <BrandColors
            web
            colors={["#CE825E", "#D8D8DA", "#1C1C1C", "#FFF"]}
            minorColors={["#BCD3D8", "#D3E0E2", "#E2CEBF", "#E53D1C"]}
          />
          <Spacing size={screenWidth * 0.1} />
        </View>
      </View>
      <Image
        resizeMode="cover"
        source={require(`../../assets/fincap4${size}.webp`)}
        style={{ width: screenWidth, height: screenWidth * 0.8 }}
      />
      <Image
        source={require(`../../assets/fincap5${size}.webp`)}
        style={{ width: screenWidth, height: screenWidth * 0.9 }}
      />
      <View
        style={[
          {
            marginTop: screenWidth * 0.05,
            height: screenWidth * 0.53,
          },
        ]}
      >
        <Image
          source={require("../../assets/fincap6.webp")}
          style={{
            width: screenWidth * 0.6,
            height: screenWidth * 0.3,
            position: "absolute",
            right: screenWidth * 0.05,
          }}
        />
        <Image
          source={require(`../../assets/fincap7${size}.webp`)}
          style={{
            width: screenWidth * 0.4,
            height: screenWidth * 0.27,
            position: "absolute",
            top: screenWidth * 0.22,
            left: screenWidth * 0.05,
          }}
        />
      </View>
      <Spacing size={screenWidth * 0.1} />
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  logo: {
    height: 200,
  },
  logoContainer: { justifyContent: "space-between" },
  content: {
    paddingTop: 20,
    backgroundColor: "#FFF",
  },
  webBrands: {
    flex: 1,
    justifyContent: "space-around",
    flexDirection: "row",
    backgroundColor: "#FFF",
  },
  bottomImages: {
    backgroundColor: "red",
  },
});

export default Fincap;
