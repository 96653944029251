import React from "react";
import { View, StyleSheet, Image, useWindowDimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  ScreenContainer,
  Content,
  BackButton,
  Spacing,
  Text,
  BrandColors,
  FontExamples,
  Link,
  ProjectDescription,
} from "../components";
import { getLayout, appStyles, getSize, relSize } from "../theme";

const Makitalo = () => {
  const { width: screenWidth } = useWindowDimensions();
  const { size, isMobile } = getLayout(screenWidth);
  const { goBack, navigate } = useNavigation();

  const miniImgStyle = {
    width: screenWidth * 0.47,
    height: screenWidth * 0.47,
    marginTop: -15,
  };

  /* @ts-ignore */
  const onNextPress = () => navigate("Kivikyla");

  const rel = (s: number, min?: number, max?: number) =>
    relSize({ screenWidth, size: s, min, max });

  if (isMobile) {
    return (
      <ScreenContainer
        pageTitle="Mäkitalon farmi"
        onNextPress={onNextPress}
        onBackPress={goBack}
      >
        <Content>
          <BackButton />
          <Spacing />
        </Content>
        <Image
          source={require(`../../assets/makitalo1${size}.webp`)}
          style={{ width: screenWidth, height: screenWidth }}
        />
        <View style={styles.content}>
          <Spacing size={30} />
          <Content>
            <Text small>
              {"Branding / Identity / Logo\nDesign / Package Design"}
            </Text>
          </Content>
          <Spacing />
          <Image
            resizeMode="cover"
            source={require("../../assets/makitalo2.svg")}
            style={styles.logo}
          />
          <BrandColors
            colors={["#FFFAD9", "#E6AB0E", "#E87A67", "#CF6200"]}
            minorColors={["#6784AF", "#6E8A37", "#84644A"]}
            equalSize
          />
          <Spacing size={40} />
          <FontExamples page="Makitalo" />
          <Spacing size={50} />
        </View>
        <View style={styles.imageContainer}>
          <Image
            resizeMode="cover"
            source={require(`../../assets/makitalo3${size}.webp`)}
            style={{ width: screenWidth, height: screenWidth * 0.8 }}
          />
          <Spacing />
          <Image
            resizeMode="cover"
            source={require(`../../assets/makitalo4${size}.webp`)}
            style={{
              width: screenWidth,
              height: screenWidth,
              marginRight: -25,
              marginBottom: -30,
            }}
          />
          <Image
            resizeMode="contain"
            source={require(`../../assets/makitalo5${size}.webp`)}
            style={{
              width: screenWidth,
              height: screenWidth * 0.9,
            }}
          />
          <View style={styles.doubleImage}>
            <Image
              resizeMode="contain"
              source={require("../../assets/makitalo6_1.webp")}
              style={miniImgStyle}
            />
            <Image
              resizeMode="contain"
              source={require("../../assets/makitalo6_2.webp")}
              style={miniImgStyle}
            />
          </View>
          <View style={styles.doubleImage}>
            <Image
              resizeMode="contain"
              source={require("../../assets/makitalo6_3.webp")}
              style={miniImgStyle}
            />
            <Image
              resizeMode="contain"
              source={require("../../assets/makitalo6_4.webp")}
              style={miniImgStyle}
            />
          </View>
          <View style={styles.doubleImage}>
            <Image
              resizeMode="contain"
              source={require("../../assets/makitalo6_5.webp")}
              style={miniImgStyle}
            />
            <Image
              resizeMode="contain"
              source={require("../../assets/makitalo6_6.webp")}
              style={miniImgStyle}
            />
          </View>
          <Spacing size={50} />
        </View>
        <View style={styles.content}>
          <Image
            resizeMode="cover"
            source={require(`../../assets/makitalo7${size}.webp`)}
            style={{
              width: screenWidth,
              height: screenWidth * 1.2,
              marginVertical: -20,
            }}
          />
          <Image
            resizeMode="cover"
            source={require(`../../assets/makitalo8${size}.webp`)}
            style={{
              width: screenWidth,
              height: screenWidth * 0.9,
              marginTop: -40,
              marginBottom: -20,
            }}
          />
          <Spacing size={40} />
        </View>
        <Spacing />
      </ScreenContainer>
    );
  }

  return (
    <ScreenContainer
      pageTitle="Mäkitalon farmi"
      btmColor="#FFFAD9"
      onNextPress={onNextPress}
      onBackPress={goBack}
    >
      <View style={appStyles(screenWidth).webHeader}>
        <Image
          resizeMode="contain"
          source={require(`../../assets/makitalo1${size}.webp`)}
          style={{
            width: getSize(1140, screenWidth),
            height: getSize(902, screenWidth),
          }}
        />
        <ProjectDescription
          client="HK / Mäkitalon farmi"
          role="Art Director, Graphic Designer in Branding / Identity / Logo Design / Package design"
        />
      </View>
      <View
        style={[
          styles.webBrands,
          {
            paddingTop: screenWidth * 0.1,
            paddingRight: screenWidth * 0.08,
            minHeight: relSize({ screenWidth, size: 1000 }),
          },
        ]}
      >
        <Image
          resizeMode="contain"
          source={require("../../assets/makitalo2.svg")}
          style={{
            width: screenWidth * 0.24,
            height: screenWidth * 0.15,
            marginTop: -screenWidth * 0.03,
            marginLeft: screenWidth * 0.01,
          }}
        />
        <View>
          <FontExamples web page="Makitalo" />
          <Spacing size={screenWidth * 0.05} />
        </View>
        <View>
          <BrandColors
            web
            minorColors={["#FFFAD9", "#E6AB0E", "#E87A67", "#CF6200"]}
            colors={["#6784AF", "#6E8A37", "#84644A"]}
            equalSize
          />
          <Spacing size={screenWidth * 0.05} />
        </View>
      </View>
      <Image
        resizeMode="cover"
        source={require(`../../assets/makitalo3${size}.webp`)}
        style={{ width: screenWidth, height: screenWidth * 0.53 }}
      />
      <Spacing size={rel(50)} />
      <View style={styles.doubleImage}>
        <Image
          resizeMode="cover"
          source={require(`../../assets/makitalo4${size}.webp`)}
          style={{
            width: rel(1264),
            height: rel(1023),
            marginLeft: -rel(40),
          }}
        />
        <Image
          resizeMode="contain"
          source={require(`../../assets/makitalo5${size}.webp`)}
          style={{
            width: rel(884),
            height: rel(927),
            marginLeft: -rel(290),
            marginTop: rel(220),
          }}
        />
      </View>
      <View
        style={[
          styles.doubleImage,
          {
            justifyContent: "space-around",
            paddingHorizontal: rel(150),
            marginTop: -rel(50),
          },
        ]}
      >
        <Image
          resizeMode="contain"
          source={require("../../assets/makitalo6_1.webp")}
          style={{ width: rel(480), height: rel(480) }}
        />
        <Image
          resizeMode="contain"
          source={require("../../assets/makitalo6_2.webp")}
          style={{ width: rel(480), height: rel(480) }}
        />
        <Image
          resizeMode="contain"
          source={require("../../assets/makitalo6_3.webp")}
          style={{ width: rel(480), height: rel(480) }}
        />
      </View>
      <View
        style={[
          styles.doubleImage,
          {
            justifyContent: "space-around",
            paddingHorizontal: rel(150),
          },
        ]}
      >
        <Image
          resizeMode="contain"
          source={require("../../assets/makitalo6_4.webp")}
          style={{ width: rel(480), height: rel(480) }}
        />
        <Image
          resizeMode="contain"
          source={require("../../assets/makitalo6_5.webp")}
          style={{ width: rel(480), height: rel(480) }}
        />
        <Image
          resizeMode="contain"
          source={require("../../assets/makitalo6_6.webp")}
          style={{ width: rel(480), height: rel(480) }}
        />
      </View>
      <Spacing size={rel(150)} />
      <View style={styles.content}>
        <View style={styles.doubleImage}>
          <Image
            resizeMode="cover"
            source={require(`../../assets/makitalo8${size}.webp`)}
            style={{
              width: rel(1250),
              height: rel(833),
            }}
          />
          <Image
            resizeMode="cover"
            source={require(`../../assets/makitalo7${size}.webp`)}
            style={{
              width: rel(994),
              height: rel(740),
              marginLeft: -rel(300),
            }}
          />
        </View>
        <View
          style={{
            alignItems: "flex-end",
            paddingRight: rel(220),
          }}
        ></View>
        <Spacing size={screenWidth * 0.1} />
      </View>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  logo: {
    height: 200,
    marginBottom: 20,
  },
  content: {
    backgroundColor: "#FFFAD9",
  },
  imageContainer: {
    alignItems: "center",
    width: "100vw",
  },
  doubleImage: {
    flexDirection: "row",
    alignItems: "center",
  },
  webBrands: {
    flex: 1,
    justifyContent: "space-around",
    flexDirection: "row",
    backgroundColor: "#FFFAD9",
  },
});

export default Makitalo;
