import React from "react";
import { TouchableOpacity, StyleSheet, Linking } from "react-native";
import Text from "./Text";

interface LinkProps {
  url: string;
  label: string;
  web?: boolean;
}

const Link = ({ url, label, web }: LinkProps) => {
  const openLink = () =>
    Linking.canOpenURL(url)
      .then((canOpen) => !!canOpen && Linking.openURL(url))
      .catch((e) => {});

  return (
    <TouchableOpacity onPress={openLink} style={styles.link}>
      <Text small={!web}>{label}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  link: {
    alignItems: "center",
    padding: 5,
  },
});

export default Link;
