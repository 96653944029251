import React from "react";
import { TouchableOpacity, useWindowDimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import Text from "./Text";
import { getLayout } from "../theme";

const BackButton = () => {
  const { navigate } = useNavigation();
  const { width: screenWidth } = useWindowDimensions();
  const { isMobile } = getLayout(screenWidth);

  if (isMobile) {
    return (
      /* @ts-ignore */
      <TouchableOpacity onPress={() => navigate("Home")}>
        <Text textStyle={{ fontSize: 20 }}>
          {"< "}
          <Text underlined textStyle={{ fontSize: 20 }}>
            BACK
          </Text>
        </Text>
      </TouchableOpacity>
    );
  }

  return (
    /* @ts-ignore */
    <TouchableOpacity onPress={() => navigate("Home")}>
      <Text webSmall>
        {"< "}
        <Text webSmall underlined>
          BACK
        </Text>
      </Text>
    </TouchableOpacity>
  );
};

export default BackButton;
