import React from "react";
import { View, StyleSheet, useWindowDimensions } from "react-native";
import Svg, { Polygon } from "react-native-svg";
import { relSize } from "../theme";

interface DividerProps {
  fincap?: boolean;
  helmikallio?: boolean;
  web?: boolean;
}

const Divider = ({ fincap, helmikallio, web }: DividerProps) => {
  const { width: screenWidth } = useWindowDimensions();

  const rel = (s: number, min?: number, max?: number) =>
    relSize({ screenWidth, size: s, min, max });

  if (helmikallio) {
    return (
      <View style={styles.container}>
        <View style={styles.verticalContent}>
          {Array.from(Array(3).keys()).map((i) => (
            <View key={`dot${i}`} style={styles.largeDot} />
          ))}
        </View>
      </View>
    );
  }
  if (fincap) {
    const divWidth = web ? screenWidth * 0.31 : screenWidth;
    const divHeight = web ? screenWidth * 0.09 : screenWidth * 0.3;
    return (
      <Svg height={divHeight} width={divWidth}>
        <Polygon
          points={`${divWidth / 2},0 0,${divHeight} ${divWidth},${divHeight}`}
          fill="#CE825E"
        />
      </Svg>
    );
  }
  return (
    <View style={styles.container}>
      <View style={[styles.content, web && styles.webContent]}>
        {Array.from(Array(5).keys()).map((i) => (
          <View
            key={`dot${i}`}
            style={[
              styles.dot,
              web && {
                height: rel(20),
                width: rel(20),
                borderRadius: rel(10),
              },
            ]}
          />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  content: {
    width: 150,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  webContent: {
    width: 400,
  },
  verticalContent: {
    height: 100,
  },
  dot: {
    backgroundColor: "black",
    height: 7,
    width: 7,
    borderRadius: 3.5,
  },
  largeDot: {
    backgroundColor: "black",
    height: 16,
    width: 16,
    borderRadius: 8,
    marginBottom: 25,
  },
});

export default Divider;
