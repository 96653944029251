import React from "react";
import {
  TouchableOpacity,
  Image,
  StyleSheet,
  useWindowDimensions,
  ImageProps,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { ImageName } from "../types";
import { getLayout } from "../theme";

interface SquareImageProps {
  name: ImageName;
  pressable?: boolean;
}

const images = {
  Aland: require(`../../assets/aland1${getLayout().size}.webp`),
  Makitalo: require(`../../assets/makitalo1${getLayout().size}.webp`),
  Fincap: require("../../assets/fincap1.svg"),
  Fazer: require(`../../assets/fazer1${getLayout().size}.webp`),
  Kivikyla: require("../../assets/kivikyla1.svg"),
  Helmikallio: require(`../../assets/helmikallio1${getLayout().size}.webp`),
  Mbakery: require("../../assets/mbakery1.svg"),
};

const SquareImage = ({ name, pressable }: SquareImageProps) => {
  const { width: screenWidth } = useWindowDimensions();
  const { navigate } = useNavigation();

  /* @ts-ignore */
  const onButtonPress = () => navigate(name);

  return (
    <TouchableOpacity
      onPress={onButtonPress}
      style={[styles.button, name === "Mbakery" && styles.purple]}
      disabled={!pressable}
    >
      <Image
        style={{ width: screenWidth, height: screenWidth }}
        source={images[name]}
      />
    </TouchableOpacity>
  );
};

export const LongImage = ({ source }: ImageProps) => {
  const { width: screenWidth } = useWindowDimensions();
  return (
    <Image
      resizeMode="cover"
      source={source}
      style={{ width: screenWidth, height: screenWidth * 1.4 }}
    />
  );
};

const styles = StyleSheet.create({
  button: {
    marginBottom: 30,
  },
  purple: {
    backgroundColor: "#96339B",
  },
});

export default SquareImage;
