import React, { ReactNode, useMemo, useRef } from "react";
import {
  View,
  StyleSheet,
  useWindowDimensions,
  TouchableOpacity,
} from "react-native";
import Header from "./Header";
import Spacing from "./Spacing";
import Text from "./Text";
import { colors, getLayout, relSize } from "../theme";
import { SafeAreaView } from "react-native-safe-area-context";

interface ScreenContainerProps {
  children: ReactNode;
  pageTitle: string;
  btmColor?: string;
  onNextPress?: () => void;
  onBackPress?: () => void;
  hideBackBtn?: boolean;
  hideNav?: boolean;
}

const ScreenContainer = ({
  children,
  pageTitle,
  btmColor,
  onNextPress,
  onBackPress,
  hideBackBtn,
  hideNav,
}: ScreenContainerProps) => {
  const { width: screenWidth } = useWindowDimensions();
  const { isMobile } = getLayout(screenWidth);

  const rel = (s: number, min?: number, max?: number) =>
    relSize({ screenWidth, size: s, min, max });

  const navButtons = useMemo(() => {
    if (!onNextPress) return null;
    return (
      <View
        style={{
          flexDirection: "row",
          paddingBottom: isMobile ? 0 : rel(100),
          paddingRight: isMobile ? 50 : 0,
          marginTop: isMobile ? 30 : 0,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: btmColor ?? "transparent",
        }}
      >
        <TouchableOpacity onPress={onBackPress}>
          <Text webSmall={!isMobile}>
            {"< "}
            <Text webSmall={!isMobile} underlined>
              BACK
            </Text>
          </Text>
        </TouchableOpacity>
        <Spacing vertical size={isMobile ? 50 : 150} />
        <TouchableOpacity onPress={onNextPress}>
          <Text webSmall={!isMobile}>
            <Text webSmall={!isMobile} underlined>
              NEXT
            </Text>
            {" >"}
          </Text>
        </TouchableOpacity>
      </View>
    );
  }, []);

  return (
    <SafeAreaView
      //style={{ flex: 1, backgroundColor: "red", padding: 50 }}
      //ref={scrollRef}
      style={[!isMobile && { height: "100vh" }, { backgroundColor: colors.bg }]}
    >
      <Header title={pageTitle} hideBackBtn={hideBackBtn} hideNav={hideNav} />
      <Spacing size={isMobile ? 150 : 50} />
      {children}
      {navButtons}
      {isMobile && <Spacing size={!!onNextPress ? 50 : 100} />}
    </SafeAreaView>
  );
};

interface ContentProps {
  children: ReactNode;
}

export const Content = ({ children }: ContentProps) => (
  <View style={styles.content}>{children}</View>
);

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 30,
  },
});

export default ScreenContainer;
