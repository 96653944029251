import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import Svg, { Rect } from "react-native-svg";
import { colors } from "../theme";

interface MenuButtonProps {
  onPress: () => void;
}

const MenuButton = ({ onPress }: MenuButtonProps) => (
  <TouchableOpacity style={styles.container} onPress={onPress}>
    <View>
      <Svg width="65" height="23">
        <Rect y="0" width="65" height="5" fill={colors.text} />
        <Rect y="9" width="65" height="5" fill={colors.text} />
        <Rect y="18" width="65" height="5" fill={colors.text} />
      </Svg>
    </View>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  container: {
    marginLeft: 10,
  },
});

export default MenuButton;
