import React from "react";
import { View, StyleSheet, Image, useWindowDimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  ScreenContainer,
  Content,
  BackButton,
  Spacing,
  Text,
  ProjectDescription,
} from "../components";
import { getLayout, relSize, appStyles } from "../theme";

const MBakery = () => {
  const { width: screenWidth } = useWindowDimensions();
  const { size, isMobile } = getLayout(screenWidth);
  const { goBack, navigate } = useNavigation();

  /* @ts-ignore */
  const onNextPress = () => navigate("Fazer");

  const rel = (s: number, min?: number, max?: number) =>
    relSize({ screenWidth, size: s, min, max });

  if (isMobile) {
    return (
      <ScreenContainer
        pageTitle="Mbakery"
        onNextPress={onNextPress}
        onBackPress={goBack}
      >
        <Content>
          <BackButton />
          <Spacing />
        </Content>
        <Image
          source={require(`../../assets/mbakery2${size}.webp`)}
          style={{ width: screenWidth, height: screenWidth }}
        />
        <Spacing size={30} />
        <Content>
          <Text small>Package Design / Logo Design</Text>
        </Content>
        <Spacing />
        <View style={styles.purpleLogo}>
          <Image
            source={require("../../assets/mbakery1.svg")}
            style={{ width: screenWidth, height: screenWidth }}
          />
        </View>
        <View style={styles.goldLogo}>
          <Image
            source={require("../../assets/mbakery3.svg")}
            style={{ width: screenWidth, height: screenWidth }}
          />
        </View>
        <View style={styles.lightPurple}>
          <Image
            source={require(`../../assets/mbakery4${size}.webp`)}
            style={{ width: screenWidth, height: screenWidth }}
          />
        </View>
        <View style={styles.lightGold}>
          <Image
            source={require(`../../assets/mbakery5${size}.webp`)}
            style={{ width: screenWidth, height: screenWidth }}
          />
        </View>
        <View style={styles.lightPurple}>
          <Image
            source={require(`../../assets/mbakery6${size}.webp`)}
            style={{ width: screenWidth, height: screenWidth }}
          />
        </View>
        <View style={styles.lightGold}>
          <Image
            source={require(`../../assets/mbakery7${size}.webp`)}
            style={{ width: screenWidth, height: screenWidth }}
          />
        </View>
        <Spacing />
      </ScreenContainer>
    );
  }

  return (
    <ScreenContainer
      pageTitle="Mbakery"
      onNextPress={onNextPress}
      onBackPress={goBack}
    >
      <View style={appStyles(screenWidth).webHeader}>
        <Image
          resizeMode="contain"
          source={require(`../../assets/mbakery2${size}.webp`)}
          style={{
            width: rel(1190),
            height: rel(904),
          }}
        />
        <ProjectDescription
          client="MBakery"
          role="Art Director, Graphic Designer in Logo Design / Package design"
        />
      </View>
      <View style={styles.row}>
        <View
          style={[
            styles.goldLogo,
            {
              width: screenWidth * 0.5,
              height: rel(830),
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
        >
          <Image
            source={require("../../assets/mbakery3.svg")}
            style={{ width: screenWidth * 0.5, height: rel(830) }}
          />
        </View>
        <View
          style={[
            styles.purpleLogo,
            {
              width: screenWidth * 0.5,
              height: rel(830),
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
        >
          <Image
            source={require("../../assets/mbakery1.svg")}
            style={{ width: rel(830), height: rel(830) }}
          />
        </View>
      </View>
      <View style={styles.row}>
        <View
          style={[
            styles.lightPurple,
            {
              width: screenWidth * 0.5,
              height: screenWidth * 0.5,
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
        >
          <Image
            source={require(`../../assets/mbakery4${size}.webp`)}
            style={{
              width: screenWidth * 0.5,
              height: screenWidth * 0.5,
              marginTop: -rel(100),
            }}
          />
        </View>
        <View
          style={[
            styles.lightGold,
            {
              width: screenWidth * 0.5,
              height: screenWidth * 0.5,
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
        >
          <Image
            source={require(`../../assets/mbakery7${size}.webp`)}
            style={{
              width: screenWidth * 0.5,
              height: screenWidth * 0.5,
              marginTop: -rel(100),
            }}
          />
        </View>
      </View>
      <View style={styles.row}>
        <View
          style={[
            styles.lightGold,
            {
              width: screenWidth * 0.5,
              height: screenWidth * 0.5,
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
        >
          <Image
            source={require(`../../assets/mbakery5${size}.webp`)}
            style={{
              width: screenWidth * 0.5,
              height: screenWidth * 0.5,
              marginTop: -rel(100),
            }}
          />
        </View>
        <View
          style={[
            styles.lightPurple,
            {
              width: screenWidth * 0.5,
              height: screenWidth * 0.5,
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
        >
          <Image
            source={require(`../../assets/mbakery6${size}.webp`)}
            style={{
              width: screenWidth * 0.5,
              height: screenWidth * 0.5,
              marginTop: -rel(100),
            }}
          />
        </View>
      </View>
      <Spacing size={screenWidth * 0.1} />
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  purpleLogo: {
    backgroundColor: "#96339B",
  },
  goldLogo: {
    backgroundColor: "#D0B278",
  },
  lightPurple: {
    backgroundColor: "#96339B80",
  },
  lightGold: {
    backgroundColor: "#D0B27880",
  },
  row: {
    flexDirection: "row",
  },
});

export default MBakery;
