import React from "react";
import {
  View,
  TouchableOpacity,
  StyleSheet,
  StyleProp,
  ViewStyle,
  useWindowDimensions,
} from "react-native";
import { SimpleLineIcons } from "@expo/vector-icons";
import Text from "./Text";
import { colors, getLayout } from "../theme";

interface ToTopButtonProps {
  onPress: () => void;
  backgroundColor?: string;
  style?: StyleProp<ViewStyle>;
}

const ToTopButton = ({ onPress, backgroundColor, style }: ToTopButtonProps) => {
  const { width: screenWidth } = useWindowDimensions();
  const { isMobile } = getLayout(screenWidth);
  return (
    <View
      style={[
        styles.container,
        !!backgroundColor && { backgroundColor },
        style,
      ]}
    >
      <TouchableOpacity onPress={onPress} style={styles.button}>
        <SimpleLineIcons name="arrow-up" size={20} color={colors.text} />
        <Text upLabel textStyle={[{ fontSize: 16, lineHeight: 18 }]}>
          UP
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    alignItems: "center",
  },
});

export default ToTopButton;
