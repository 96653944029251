import React from "react";
import {
  View,
  StyleSheet,
  Text,
  TextStyle,
  useWindowDimensions,
} from "react-native";
import { Spacing } from "../components";
import { ProjectName } from "../types";
import { relSize } from "../theme";

interface FontExamplesProps {
  page: ProjectName;
  web?: boolean;
}

const FontExamples = ({ page, web }: FontExamplesProps) => {
  const { width: screenWidth } = useWindowDimensions();

  const rel = (s: number, min?: number, max?: number) =>
    relSize({ screenWidth, size: s, min, max });

  const getText = (): { first: any; second: any; third: any; fourth?: any } => {
    const basic = {
      first: "ABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ",
      second: "abcdefghijklmnopqrstuvwxyzåäö\n1234567890!?",
      third: "abcdefghijklmnopqrstuvwxyzåäö\n1234567890!?+",
    };

    const thirdFull =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ\nabcdefghijklmnopqrstuvwxyzåäö\n1234567890!?+";

    switch (page) {
      case "Fincap":
        return {
          ...basic,
          third: thirdFull,
        };
      case "Makitalo":
        return {
          ...basic,
          third: thirdFull,
        };
      case "Helmikallio":
        return {
          ...basic,
          third: thirdFull,
          fourth: thirdFull,
        };
      default:
        return basic;
    }
  };

  const getStyles = () => {
    switch (page) {
      case "Aland":
        const aBasic = {
          fontFamily: "GopherMono",
          fontSize: web ? rel(40) : 28,
        };
        return {
          first: aBasic,
          second: aBasic,
          third: {
            fontFamily: "Rubik",
            fontSize: web ? rel(25) : 18,
          },
        };
      case "Fincap":
        const fBasic: TextStyle = {
          fontFamily: "Henderson",
          fontSize: web ? rel(40) : 25,
          fontWeight: "bold",
          lineHeight: web ? rel(50) : 35,
        };
        return {
          first: fBasic,
          second: fBasic,
          third: {
            fontFamily: "Bodoni",
            fontSize: web ? rel(35) : 20,
            lineHeight: web ? rel(50) : 28,
          },
        };
      case "Makitalo":
        const mBasic = {
          color: "#6784AF",
          fontFamily: "Bevan",
          fontSize: web ? rel(50) : 25,
          lineHeight: web ? rel(65) : 35,
        };
        return {
          first: mBasic,
          second: mBasic,
          third: {
            color: "#6784AF",
            fontFamily: "InputSans",
            fontSize: web ? rel(30) : 20,
            lineHeight: web ? rel(40) : 30,
          },
        };
      case "Helmikallio":
        const hBasic = {
          fontFamily: "Bely",
          fontSize: web ? rel(40) : 30,
          lineHeight: web ? rel(48) : 37,
        };
        return {
          first: hBasic,
          second: hBasic,
          third: {
            fontFamily: "Henderson",
            fontSize: web ? rel(26) : 20,
            lineHeight: web ? 32 : 24,
          },
          fourth: {
            fontFamily: "Ayuthaya",
            fontSize: web ? rel(25) : 18,
            lineHeight: web ? rel(35) : 25,
          },
        };
      default:
        return {};
    }
  };

  const fontStyles = getStyles();
  const text = getText();

  if (!web) {
    return (
      <View style={styles.container}>
        <View>
          <View>
            <Text style={fontStyles.first}>{text.first}</Text>
            <Text style={fontStyles.second}>{text.second}</Text>
          </View>
          <Spacing size={20} />
          <View>
            <Text style={fontStyles.third}>{text.third}</Text>
            {!!text.fourth && (
              <>
                <Spacing size={20} />
                <Text style={fontStyles.fourth}>{text.fourth}</Text>
              </>
            )}
          </View>
          <Spacing />
        </View>
      </View>
    );
  }

  switch (page) {
    case "Aland":
      return (
        <View style={[styles.webContainer, { maxWidth: rel(800) }]}>
          <View style={{ flex: 1, maxWidth: rel(370) }}>
            <Text style={fontStyles.first}>{text.first}</Text>
            <Text style={fontStyles.second}>{text.second}</Text>
          </View>
          <Spacing size={rel(30)} vertical />
          <View style={{ flex: 1, maxWidth: rel(370) }}>
            <Text style={fontStyles.third}>{text.third}</Text>
            {!!text.fourth && (
              <>
                <Spacing size={rel(20)} />
                <Text style={fontStyles.fourth}>{text.fourth}</Text>
              </>
            )}
          </View>
          <Spacing />
        </View>
      );

    case "Makitalo":
      return (
        <View
          style={[
            styles.webContainer,
            styles.webNarrow,
            { maxWidth: rel(520) },
          ]}
        >
          <View
            style={{
              flex: 1,
              maxWidth: rel(520),
              paddingBottom: rel(130),
            }}
          >
            <Text style={fontStyles.first}>{text.first}</Text>
            <Text style={fontStyles.second}>{text.second}</Text>
          </View>
          <View style={{ flex: 1, maxWidth: rel(520) }}>
            <Text style={fontStyles.third}>{text.third}</Text>
          </View>
        </View>
      );

    case "Helmikallio":
      return (
        <View
          style={[
            styles.webContainer,
            styles.webNarrow,
            { maxWidth: rel(450) },
          ]}
        >
          <View>
            <View style={{ flex: 1, maxWidth: rel(400) }}>
              <Text style={fontStyles.first}>{text.first}</Text>
              <Text style={fontStyles.second}>{text.second}</Text>
            </View>
            <View
              style={{
                flex: 1,
                maxWidth: rel(400),
                paddingBottom: rel(120),
              }}
            >
              <Text style={fontStyles.third}>{text.third}</Text>
              {!!text.fourth && (
                <>
                  <Spacing size={screenWidth * 0.025} />
                  <Text style={fontStyles.fourth}>{text.fourth}</Text>
                </>
              )}
            </View>
          </View>
        </View>
      );

    default:
      return (
        <View
          style={[
            styles.webContainer,
            styles.webNarrow,
            { maxWidth: rel(450) },
          ]}
        >
          <View>
            <View
              style={[
                { flex: 1, maxWidth: rel(400) },
                page === "Fincap" && {
                  maxWidth: rel(380),
                  paddingBottom: rel(60),
                },
              ]}
            >
              <Text style={fontStyles.first}>{text.first}</Text>
              <Text style={fontStyles.second}>{text.second}</Text>
            </View>
            <Spacing size={rel(20)} />
            <View style={{ flex: 1, maxWidth: rel(400) }}>
              <Text style={fontStyles.third}>{text.third}</Text>
              {!!text.fourth && (
                <>
                  <Spacing size={screenWidth * 0.025} />
                  <Text style={fontStyles.fourth}>{text.fourth}</Text>
                </>
              )}
            </View>
          </View>
        </View>
      );
  }
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
    width: 300,
  },
  webContainer: {
    flex: 1,
    flexDirection: "row",
    marginRight: 30,
  },
  webNarrow: {
    flexDirection: "column",
  },
});

export default FontExamples;
