import React from "react";
import { View, StyleSheet, Image, useWindowDimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  ScreenContainer,
  Content,
  BackButton,
  Spacing,
  Text,
  ProjectDescription,
} from "../components";
import { getLayout, relSize, appStyles } from "../theme";

const Kivikyla = () => {
  const { width: screenWidth } = useWindowDimensions();
  const { size, isMobile } = getLayout(screenWidth);
  const { goBack, navigate } = useNavigation();

  /* @ts-ignore */
  const onNextPress = () => navigate("Mbakery");

  const rel = (s: number, min?: number, max?: number) =>
    relSize({ screenWidth, size: s, min, max });

  if (isMobile) {
    return (
      <ScreenContainer
        pageTitle="KOT&GO"
        onNextPress={onNextPress}
        onBackPress={goBack}
      >
        <Content>
          <BackButton />
          <Spacing />
        </Content>
        <Image
          source={require(`../../assets/kivikyla2${size}.webp`)}
          style={{ width: screenWidth, height: screenWidth }}
        />
        <Spacing size={30} />
        <Content>
          <Text small>Package Design / Logo Design</Text>
        </Content>
        <Spacing />
        <View style={styles.content}>
          <View style={styles.logo}>
            <Image
              source={require("../../assets/kivikyla3.svg")}
              style={{ width: screenWidth, height: screenWidth * 0.9 }}
            />
          </View>
          <Image
            source={require("../../assets/kivikyla1.svg")}
            style={{ width: screenWidth, height: screenWidth * 0.9 }}
          />
          <Image
            source={require(`../../assets/kivikyla4${size}.webp`)}
            style={{ width: screenWidth, height: screenWidth * 0.8 }}
          />
          <Spacing size={40} />
          <Content>
            <Text small>Concepts</Text>
          </Content>
          <Image
            source={require(`../../assets/kivikyla5${size}.webp`)}
            style={{ width: screenWidth, height: screenWidth }}
          />
          <Image
            resizeMode="contain"
            source={require(`../../assets/kivikyla6${size}.webp`)}
            style={{
              width: screenWidth,
              height: screenWidth * 0.5,
              marginTop: -20,
            }}
          />
          <Spacing />
          <Image
            source={require(`../../assets/kivikyla7${size}.webp`)}
            style={{ width: screenWidth, height: screenWidth }}
          />
          <Image
            resizeMode="contain"
            source={require(`../../assets/kivikyla8${size}.webp`)}
            style={{
              width: screenWidth,
              height: screenWidth * 0.5,
              marginTop: -20,
            }}
          />
          <Spacing />
          <Image
            source={require(`../../assets/kivikyla9${size}.webp`)}
            style={{ width: screenWidth, height: screenWidth }}
          />
          <Image
            resizeMode="contain"
            source={require(`../../assets/kivikyla10${size}.webp`)}
            style={{
              width: screenWidth,
              height: screenWidth * 0.5,
              marginTop: -20,
            }}
          />
          <Spacing />
          <Image
            source={require(`../../assets/kivikyla11${size}.webp`)}
            style={{ width: screenWidth, height: screenWidth }}
          />
          <Image
            resizeMode="contain"
            source={require(`../../assets/kivikyla12${size}.webp`)}
            style={{
              width: screenWidth * 0.5,
              height: screenWidth,
              alignSelf: "center",
              marginTop: -45,
            }}
          />
          <Spacing />
        </View>
      </ScreenContainer>
    );
  }

  return (
    <ScreenContainer
      pageTitle="KOT&GO"
      onNextPress={onNextPress}
      onBackPress={goBack}
    >
      <View style={appStyles(screenWidth).webHeader}>
        <Image
          resizeMode="contain"
          source={require(`../../assets/kivikyla2${size}.webp`)}
          style={{
            width: rel(1252),
            height: rel(840),
          }}
        />
        <ProjectDescription
          client="HK / Kivikylä"
          role="Art Director, Graphic Designer in Logo Design / Package design"
        />
      </View>
      <View style={styles.row}>
        <View
          style={[
            styles.logo,
            {
              width: screenWidth * 0.5,
              height: screenWidth * 0.5,
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
        >
          <Image
            source={require("../../assets/kivikyla3.svg")}
            style={{ width: screenWidth * 0.4, height: screenWidth * 0.4 }}
          />
        </View>
        <View
          style={[
            styles.content,
            {
              width: screenWidth * 0.5,
              height: screenWidth * 0.5,
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
        >
          <Image
            source={require("../../assets/kivikyla1.svg")}
            style={{ width: screenWidth * 0.4, height: screenWidth * 0.4 }}
          />
        </View>
      </View>
      <Image
        source={require(`../../assets/kivikyla4${size}.webp`)}
        style={{ width: screenWidth, height: screenWidth * 0.8 }}
      />
      <View style={styles.content}>
        <View style={{ paddingLeft: rel(150), paddingTop: rel(135) }}>
          <Text web>Concepts</Text>
        </View>
        <Spacing size={rel(135)} />
        <View style={[styles.row]}>
          <View
            style={{
              width: screenWidth * 0.5,
              alignItems: "flex-end",
            }}
          >
            <View style={{ alignItems: "center" }}>
              <Image
                source={require(`../../assets/kivikyla5${size}.webp`)}
                style={{ width: rel(760), height: rel(760) }}
              />
              <Image
                resizeMode="contain"
                source={require(`../../assets/kivikyla6${size}.webp`)}
                style={{
                  width: rel(650),
                  height: rel(433),
                  marginTop: -20,
                }}
              />
            </View>
          </View>
          <View
            style={{
              width: screenWidth * 0.5,
              alignItems: "flex-start",
            }}
          >
            <View style={{ alignItems: "center" }}>
              <Image
                source={require(`../../assets/kivikyla7${size}.webp`)}
                style={{ width: rel(760), height: rel(760) }}
              />
              <Image
                resizeMode="contain"
                source={require(`../../assets/kivikyla8${size}.webp`)}
                style={{
                  width: rel(650),
                  height: rel(433),
                  marginTop: -20,
                }}
              />
            </View>
          </View>
        </View>
        <Spacing size={rel(200)} />
        <View style={[styles.row]}>
          <View
            style={{
              width: screenWidth * 0.5,
              alignItems: "flex-end",
            }}
          >
            <View style={{ alignItems: "center" }}>
              <Image
                source={require(`../../assets/kivikyla9${size}.webp`)}
                style={{ width: rel(760), height: rel(760) }}
              />
              <Image
                resizeMode="contain"
                source={require(`../../assets/kivikyla10${size}.webp`)}
                style={{
                  width: rel(650),
                  height: rel(433),
                  marginTop: -20,
                }}
              />
            </View>
          </View>
          <View
            style={{
              width: screenWidth * 0.5,
              alignItems: "flex-start",
            }}
          >
            <View style={{ alignItems: "center" }}>
              <Image
                source={require(`../../assets/kivikyla11${size}.webp`)}
                style={{ width: rel(760), height: rel(760) }}
              />
              <Image
                resizeMode="contain"
                source={require(`../../assets/kivikyla12${size}.webp`)}
                style={{
                  width: rel(413),
                  height: rel(620),
                  marginTop: -20,
                }}
              />
            </View>
          </View>
        </View>
        <Spacing size={rel(230)} />
      </View>
      <Spacing size={screenWidth * 0.1} />
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  content: {
    backgroundColor: "#FFF",
  },
  logo: {
    backgroundColor: "#FFE89A",
  },
  row: {
    flexDirection: "row",
  },
});

export default Kivikyla;
