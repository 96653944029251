import React from "react";
import { View, StyleSheet, Image, useWindowDimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  ScreenContainer,
  Content,
  BackButton,
  Spacing,
  Text,
  BrandColors,
  FontExamples,
  Divider,
  Link,
  ProjectDescription,
} from "../components";
import { getLayout, appStyles, relSize } from "../theme";

const Helmikallio = () => {
  const { width: screenWidth } = useWindowDimensions();
  const { size, isMobile } = getLayout(screenWidth);
  const { goBack, navigate } = useNavigation();

  /* @ts-ignore */
  const onNextPress = () => navigate("Makitalo");

  if (isMobile) {
    return (
      <ScreenContainer
        pageTitle={"Helmi-\nkallio"}
        onNextPress={onNextPress}
        onBackPress={goBack}
      >
        <Content>
          <BackButton />
          <Spacing />
        </Content>
        <Image
          source={require(`../../assets/helmikallio2${size}.webp`)}
          style={{ width: screenWidth, height: screenWidth }}
        />
        <Spacing size={30} />
        <Content>
          <Text small>Identity / Logo Design</Text>
        </Content>
        <Spacing />
        <View style={styles.container}>
          <Image
            source={require("../../assets/helmikallio4.svg")}
            style={{
              width: screenWidth * 0.5,
              height: screenWidth,
              position: "absolute",
              bottom: 0,
              right: 0,
            }}
          />
          <Image
            source={require("../../assets/helmikallio3.svg")}
            style={{ width: screenWidth, height: screenWidth }}
          />
          <BrandColors colors={["#081113", "#FFFFFF", "#DDE2E4"]} />
          <Spacing size={45} />
          <FontExamples page="Helmikallio" />
          <Spacing />
          <Divider helmikallio />
          <Spacing size={40} />
        </View>
        <Image
          source={require(`../../assets/helmikallio5${size}.webp`)}
          style={{
            width: screenWidth,
            height: screenWidth * 0.8,
            marginBottom: -50,
          }}
        />
        <Image
          source={require(`../../assets/helmikallio6${size}.webp`)}
          style={{
            width: screenWidth,
            height: screenWidth * 0.85,
            marginTop: -30,
            marginBottom: -20,
          }}
        />
        <Link url="https://helmikallio.fi" label="helmikallio.fi" />
        <Spacing size={50} />
        <Image
          source={require(`../../assets/helmikallio1${size}.webp`)}
          style={{ width: screenWidth, height: screenWidth * 0.8 }}
        />
        <Spacing />
      </ScreenContainer>
    );
  }

  return (
    <ScreenContainer
      pageTitle={"Helmikallio"}
      onNextPress={onNextPress}
      onBackPress={goBack}
    >
      <View style={appStyles(screenWidth).webHeader}>
        <Image
          resizeMode="contain"
          source={require(`../../assets/helmikallio2${size}.webp`)}
          style={{
            width: screenWidth * 0.63,
            height: screenWidth * 0.47,
          }}
        />
        <ProjectDescription
          client="Helmikallio"
          role="Art Director, Graphic Designer in Identity / Logo Design"
        />
      </View>
      <View
        style={[
          styles.webBrands,
          {
            minHeight: relSize({ screenWidth, size: 1200, max: 1300 }),
            height: screenWidth * 0.6,
            paddingTop: screenWidth * 0.1,
            paddingRight: screenWidth * 0.25,
          },
        ]}
      >
        <Image
          resizeMode="contain"
          source={require("../../assets/helmikallio3.svg")}
          style={{
            width: screenWidth * 0.24,
            height: screenWidth * 0.25,
            marginTop: -screenWidth * 0.03,
          }}
        />
        <FontExamples web page="Helmikallio" />
        <Spacing size={screenWidth * 0.05} />
        <View>
          <BrandColors web colors={["#081113", "#FFFFFF", "#DDE2E4"]} />
          <Spacing size={screenWidth * 0.05} />
          <Divider helmikallio />
        </View>
        <View style={styles.squiggle}>
          <Image
            source={require("../../assets/helmikallio4.svg")}
            style={{
              width: screenWidth * 0.25,
              height: screenWidth * 0.5,
            }}
          />
        </View>
      </View>
      <View style={styles.webImages}>
        <Image
          source={require(`../../assets/helmikallio5${size}.webp`)}
          style={{
            width: screenWidth * 0.57,
            height: screenWidth * 0.4,
            marginRight: -screenWidth * 0.05,
            marginLeft: -screenWidth * 0.02,
          }}
        />
        <Image
          source={require(`../../assets/helmikallio6${size}.webp`)}
          style={{
            width: screenWidth * 0.57,
            height: screenWidth * 0.4,
            marginLeft: -screenWidth * 0.05,
            marginRight: -screenWidth * 0.02,
          }}
        />
      </View>
      <View
        style={[
          styles.link,
          { marginBottom: screenWidth * 0.1, marginRight: screenWidth * 0.08 },
        ]}
      >
        <Link web url="https://helmikallio.fi" label="helmikallio.fi" />
      </View>
      <Image
        source={require(`../../assets/helmikallio1${size}.webp`)}
        style={{ width: screenWidth, height: screenWidth * 0.8 }}
      />
      <Spacing size={screenWidth * 0.1} />
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#DDE2E4",
  },
  webBrands: {
    flex: 1,
    justifyContent: "space-around",
    flexDirection: "row",
    backgroundColor: "#DDE2E4",
  },
  squiggle: {
    flex: 1,
    height: "100%",
    position: "absolute",
    top: 0,
    right: 0,
  },
  webImages: {
    flexDirection: "row",
  },
  link: {
    alignItems: "flex-end",
  },
});

export default Helmikallio;
