import React, { useEffect } from "react";
import {
  TouchableOpacity,
  View,
  StyleSheet,
  useWindowDimensions,
} from "react-native";
import * as Linking from "expo-linking";
import { Ionicons } from "@expo/vector-icons";
import { useRoute } from "@react-navigation/native";
import {
  ScreenContainer,
  Content,
  BackButton,
  Spacing,
  Text,
} from "../components";
import { colors, getLayout, relSize } from "../theme";

const Contact = () => {
  const { width: screenWidth } = useWindowDimensions();
  const { isMobile } = getLayout(screenWidth);
  const route = useRoute();

  useEffect(() => {
    route.params?.toggleUpBtn?.(false);
    return () => {
      route.params?.toggleUpBtn?.(true);
    };
  }, []);

  const rel = (s: number, min?: number, max?: number) =>
    relSize({ screenWidth, size: s, min, max });

  const openPhone = () => {
    const url = "tel:+358400537000";
    Linking.canOpenURL(url)
      .then((canOpen) => {
        if (canOpen) {
          return Linking.openURL(url);
        }
      })
      .catch(() => {});
  };

  return (
    <ScreenContainer pageTitle="Contact">
      {isMobile ? (
        <Content>
          <BackButton />
          <Spacing size={84} />
          <TouchableOpacity onPress={openPhone}>
            <Text medium>{`+358 400 537000`}</Text>
          </TouchableOpacity>
          <Spacing />
          <Text medium>hilla.kouki(at)gmail.com</Text>
          <Spacing size={90} />
          <View style={styles.location}>
            <Ionicons name="location-sharp" size={40} color={colors.text} />
            <Spacing vertical size={8} />
            <Text medium>Turku, FINLAND</Text>
          </View>
        </Content>
      ) : (
        <View style={{ marginLeft: rel(542), marginTop: rel(220) }}>
          <TouchableOpacity onPress={openPhone}>
            <Text medium>{`+358 400 537000`}</Text>
          </TouchableOpacity>
          <Spacing />
          <Text medium>hilla.kouki(at)gmail.com</Text>
          <Spacing size={90} />
          <View style={styles.location}>
            <Ionicons name="location-sharp" size={40} color={colors.text} />
            <Spacing vertical size={8} />
            <Text medium>Turku, FINLAND</Text>
          </View>
        </View>
      )}
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  location: {
    flexDirection: "row",
    alignItems: "center",
  },
});

export default Contact;
