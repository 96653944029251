import React from "react";
import {
  View,
  TouchableOpacity,
  StyleSheet,
  Image,
  useWindowDimensions,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { ScreenContainer, Spacing, SquareImage, Text } from "../components";
import { getLayout, relSize } from "../theme";
import { ImageName } from "../types";

const Home = () => {
  const { width: screenWidth } = useWindowDimensions();
  const { size, isMobile } = getLayout(screenWidth);
  const { navigate } = useNavigation();

  const rel = (s: number, min?: number, max?: number) =>
    relSize({ screenWidth, size: s, min, max });

  const onItemPress = (name: ImageName | "Home" | "About" | "Contact") =>
    /* @ts-ignore */
    navigate(name);

  if (isMobile) {
    return (
      <ScreenContainer pageTitle="Hilla Kouki">
        <SquareImage name="Aland" pressable />
        <SquareImage name="Makitalo" pressable />
        <SquareImage name="Fincap" pressable />
        <SquareImage name="Fazer" pressable />
        <SquareImage name="Kivikyla" pressable />
        <SquareImage name="Helmikallio" pressable />
        <SquareImage name="Mbakery" pressable />
      </ScreenContainer>
    );
  }

  return (
    <ScreenContainer pageTitle="Hilla Kouki">
      <View style={styles.row}>
        <View>
          <TouchableOpacity onPress={() => onItemPress("Aland")}>
            <Image
              resizeMode="contain"
              source={require(`../../assets/aland1${size}.webp`)}
              style={{
                width: rel(1118),
                height: rel(899),
              }}
            />
          </TouchableOpacity>
          <View>
            <TouchableOpacity onPress={() => onItemPress("Fazer")}>
              <Image
                resizeMode="contain"
                source={require(`../../assets/fazer_logo${size}.webp`)}
                style={{
                  width: rel(605),
                  height: rel(596),
                  marginLeft: rel(174),
                  marginTop: rel(55),
                }}
              />
            </TouchableOpacity>
          </View>
        </View>
        <View style={[styles.navigation, { marginTop: rel(30) }]}>
          <View>
            <TouchableOpacity onPress={() => onItemPress("Home")}>
              <Text web underlined>
                Work
              </Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => onItemPress("About")}>
              <Text web underlined>
                About
              </Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => onItemPress("Contact")}>
              <Text web underlined>
                Contact
              </Text>
            </TouchableOpacity>
          </View>
          <Spacing size={rel(200)} />
          <TouchableOpacity onPress={() => onItemPress("Makitalo")}>
            <Image
              resizeMode="contain"
              source={require(`../../assets/makitalo9.webp`)}
              style={{
                width: rel(646),
                height: rel(861),
              }}
            />
          </TouchableOpacity>
        </View>
      </View>
      <View
        style={[
          styles.row,
          {
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginTop: -rel(100),
          },
        ]}
      >
        <TouchableOpacity
          onPress={() => onItemPress("Kivikyla")}
          style={[styles.kivikyla, { marginLeft: rel(200), height: rel(524) }]}
        >
          <Image
            resizeMode="contain"
            source={require(`../../assets/kivikyla1.svg`)}
            style={{
              width: rel(524),
              height: rel(524),
            }}
          />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => onItemPress("Fincap")}>
          <Image
            resizeMode="contain"
            source={require(`../../assets/fincap1.svg`)}
            style={{
              width: rel(746),
              height: rel(746),
              marginRight: rel(200),
            }}
          />
        </TouchableOpacity>
      </View>
      <Spacing size={rel(176)} />
      <View style={styles.center}>
        <TouchableOpacity onPress={() => onItemPress("Helmikallio")}>
          <Image
            resizeMode="contain"
            source={require(`../../assets/helmikallio1${size}.webp`)}
            style={{
              width: rel(1492),
              height: rel(973),
            }}
          />
        </TouchableOpacity>
      </View>
      <Spacing size={rel(130)} />
      <View style={styles.row}>
        <TouchableOpacity onPress={() => onItemPress("Mbakery")}>
          <Image
            resizeMode="contain"
            source={require(`../../assets/mbakery9.webp`)}
            style={{
              width: rel(646),
              height: rel(1112),
              marginLeft: rel(95),
            }}
          />
        </TouchableOpacity>
      </View>
      <Spacing size={screenWidth * 0.1} />
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
  },
  navigation: {
    flex: 1,
    alignItems: "center",
  },
  center: {
    alignItems: "center",
  },
  mbakery: {
    backgroundColor: "#96339B",
    alignItems: "center",
    justifyContent: "center",
  },
  kivikyla: {
    backgroundColor: "#FFF",
  },
});

export default Home;
