import React from "react";
import { View, useWindowDimensions } from "react-native";
import Text from "./Text";
import { appStyles } from "../theme";

interface Props {
  client: string;
  role: string;
}

const ProjectDescription = ({ client, role }: Props) => {
  const { width: screenWidth } = useWindowDimensions();
  return (
    <View style={appStyles(screenWidth).webDesc}>
      <View style={appStyles(screenWidth).webDescBox}>
        <Text webSmall light>
          <Text webSmall bold>
            CLIENT
          </Text>
          {` ${client}`}
        </Text>
        <Text webSmall light>
          <Text webSmall bold>
            ROLE
          </Text>
          {` ${role}`}
        </Text>
      </View>
    </View>
  );
};

export default ProjectDescription;
