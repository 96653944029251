import React, { useState, ReactNode } from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import Animated from "react-native-reanimated";
import Collapsible from "react-native-collapsible";
import { SimpleLineIcons } from "@expo/vector-icons";
import Text from "./Text";
import { colors } from "../theme";
import { Spacing } from ".";

interface CollapsableSectionProps {
  title: string;
  children: ReactNode;
}

const CollapsableSection = ({ title, children }: CollapsableSectionProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.header}
        onPress={() => setOpen((prev) => !prev)}
      >
        <Text bold small capitalize>
          {title}
        </Text>
        <Spacing size={20} vertical />
        <SimpleLineIcons
          name={open ? "arrow-down" : "arrow-up"}
          size={30}
          color={colors.text}
        />
      </TouchableOpacity>
      <Collapsible collapsed={!open} collapsedHeight={0}>
        {children}
      </Collapsible>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 70,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
  },
});

export default CollapsableSection;
