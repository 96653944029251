import React from "react";
import { View, StyleSheet, Image, useWindowDimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  ScreenContainer,
  Content,
  BackButton,
  Spacing,
  Text,
  SquareImage,
  BrandColors,
  Divider,
  LongImage,
  FontExamples,
  ProjectDescription,
} from "../components";
import { getLayout, appStyles, relSize } from "../theme";

const Aland = () => {
  const { width: screenWidth } = useWindowDimensions();
  const { size, isMobile } = getLayout(screenWidth);
  const { goBack, navigate } = useNavigation();

  /* @ts-ignore */
  const onNextPress = () => navigate("Fincap");

  if (isMobile) {
    return (
      <ScreenContainer
        pageTitle="Åland distillery"
        onNextPress={onNextPress}
        onBackPress={goBack}
      >
        <Content>
          <BackButton />
          <Spacing />
        </Content>
        <SquareImage name="Aland" />
        <Content>
          <Text small>
            Branding / Identity / Logo Design / Package design / Illustrations
          </Text>
        </Content>
        <Image
          resizeMode="contain"
          source={require("../../assets/aland2.svg")}
          style={styles.logo}
        />
        <BrandColors colors={["#E3B6BA", "#1B2920"]} />
        <Spacing />
        <FontExamples page="Aland" />
        <Spacing size={35} />
        <Divider />
        <Spacing />
        <View style={styles.imageContainer}>
          <View style={styles.twigContainer}>
            <Image
              source={require("../../assets/aland3_1.webp")}
              style={styles.twigOne}
            />
            <Image
              source={require("../../assets/aland3_1.webp")}
              style={styles.twigTwo}
            />
          </View>
        </View>
        <View style={styles.imageContainer}>
          <View style={styles.orangeContainer}>
            <Image
              source={require("../../assets/aland3_2.webp")}
              style={styles.orangeOne}
            />
            <Image
              source={require("../../assets/aland3_2.webp")}
              style={styles.orangeTwo}
            />
          </View>
        </View>
        <View style={styles.imageContainer}>
          <View style={styles.basilContainer}>
            <Image
              source={require("../../assets/aland3_3.webp")}
              style={styles.basilOne}
            />
            <Image
              source={require("../../assets/aland3_3.webp")}
              style={styles.basilTwo}
            />
            <Image
              source={require("../../assets/aland3_4.webp")}
              style={styles.basilThree}
            />
            <Image
              source={require("../../assets/aland3_4.webp")}
              style={styles.basilFour}
            />
          </View>
        </View>
        <LongImage source={require(`../../assets/aland4${size}.webp`)} />
        <LongImage source={require(`../../assets/aland5${size}.webp`)} />
        <LongImage source={require(`../../assets/aland6${size}.webp`)} />
        <LongImage source={require(`../../assets/aland7${size}.webp`)} />
        <Spacing />
      </ScreenContainer>
    );
  }

  return (
    <ScreenContainer
      pageTitle="Åland distillery"
      onNextPress={onNextPress}
      onBackPress={goBack}
    >
      <View style={appStyles(screenWidth).webHeader}>
        <Image
          resizeMode="contain"
          source={require(`../../assets/aland1${size}.webp`)}
          style={{
            width: screenWidth * 0.63,
            height: screenWidth * 0.475,
          }}
        />
        <ProjectDescription
          client="Åland Distillery"
          role="Art Director, Graphic Designer in Branding / Identity / Logo Design / Package design / Illustrations"
        />
      </View>
      <View style={[styles.webBrands, { paddingRight: screenWidth * 0.04 }]}>
        <Image
          resizeMode="contain"
          source={require("../../assets/aland2.svg")}
          style={{
            width: screenWidth * 0.32,
            height: screenWidth * 0.18,
            marginTop: -50,
            marginRight: -20,
          }}
        />
        <FontExamples web page="Aland" />
        <BrandColors web colors={["#E3B6BA", "#1B2920"]} />
      </View>
      <Spacing size={relSize({ screenWidth, size: 350, min: 300, max: 500 })} />
      <Divider web />
      <Spacing size={100} />
      <View
        style={[
          styles.webVectorContainer,
          { height: screenWidth * 0.26, marginHorizontal: screenWidth * 0.05 },
        ]}
      >
        <View style={[styles.imageContainer, { flex: 1 }]}>
          <View
            style={[
              styles.twigContainer,
              {
                flex: 1,
                height: screenWidth * 0.26,
                width: screenWidth * 0.23,
              },
            ]}
          >
            <Image
              source={require("../../assets/aland3_1.webp")}
              style={[
                styles.twigOne,
                {
                  top: screenWidth * 0.02,
                  left: -screenWidth * 0.02,
                  height: screenWidth * 0.2,
                  width: screenWidth * 0.2,
                },
              ]}
            />
            <Image
              source={require("../../assets/aland3_1.webp")}
              style={[
                styles.twigTwo,
                {
                  top: -screenWidth * 0.04,
                  right: -screenWidth * 0.05,
                  height: screenWidth * 0.2,
                  width: screenWidth * 0.2,
                },
              ]}
            />
          </View>
        </View>
        <View style={[styles.imageContainer, { flex: 1 }]}>
          <View
            style={[
              styles.orangeContainer,
              {
                flex: 1,
                height: screenWidth * 0.1,
                width: screenWidth * 0.2,
              },
            ]}
          >
            <Image
              source={require("../../assets/aland3_2.webp")}
              style={[
                styles.orangeOne,
                {
                  left: screenWidth * 0.015,
                  top: screenWidth * 0.01,
                  width: screenWidth * 0.2,
                  height: screenWidth * 0.16,
                },
              ]}
            />
            <Image
              source={require("../../assets/aland3_2.webp")}
              style={[
                styles.orangeTwo,
                {
                  right: screenWidth * 0.015,
                  top: screenWidth * 0.04,
                  width: screenWidth * 0.2,
                  height: screenWidth * 0.16,
                },
              ]}
            />
          </View>
        </View>
        <View style={[styles.imageContainer, { flex: 1 }]}>
          <View
            style={[
              styles.basilContainer,
              {
                flex: 1,
                height: screenWidth * 0.26,
                width: screenWidth * 0.26,
              },
            ]}
          >
            <Image
              source={require("../../assets/aland3_3.webp")}
              style={[
                styles.basilOne,
                {
                  top: -screenWidth * 0.005,
                  left: screenWidth * 0.07,
                  width: screenWidth * 0.13,
                  height: screenWidth * 0.13,
                },
              ]}
            />
            <Image
              source={require("../../assets/aland3_3.webp")}
              style={[
                styles.basilTwo,
                {
                  right: screenWidth * 0.11,
                  top: screenWidth * 0.07,
                  width: screenWidth * 0.12,
                  height: screenWidth * 0.12,
                },
              ]}
            />
            <Image
              source={require("../../assets/aland3_4.webp")}
              style={[
                styles.basilThree,
                {
                  top: screenWidth * 0.05,
                  left: -screenWidth * 0.07,
                  width: screenWidth * 0.12,
                  height: screenWidth * 0.12,
                },
              ]}
            />
            <Image
              source={require("../../assets/aland3_4.webp")}
              style={[
                styles.basilFour,
                {
                  top: screenWidth * 0.06,
                  right: 0,
                  width: screenWidth * 0.12,
                  height: screenWidth * 0.12,
                },
              ]}
            />
          </View>
        </View>
      </View>
      <View style={styles.imageGrid}>
        <View>
          <View style={{ backgroundColor: "#ECECD9" }}>
            <Image
              resizeMode="contain"
              source={require(`../../assets/aland4${size}.webp`)}
              style={{ width: screenWidth * 0.5, height: screenWidth * 0.42 }}
            />
          </View>
          <Image
            source={require(`../../assets/aland7${size}.webp`)}
            style={{ width: screenWidth * 0.5, height: screenWidth * 0.67 }}
          />
        </View>
        <View>
          <Image
            source={require(`../../assets/aland5${size}.webp`)}
            style={{ width: screenWidth * 0.5, height: screenWidth * 0.67 }}
          />
          <View style={{ backgroundColor: "#F4B82A" }}>
            <Image
              resizeMode="contain"
              source={require(`../../assets/aland6${size}.webp`)}
              style={{ width: screenWidth * 0.5, height: screenWidth * 0.42 }}
            />
          </View>
        </View>
      </View>
      <Spacing size={screenWidth * 0.1} />
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  logo: {
    height: 200,
  },
  imageContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  webVectorContainer: {
    flexDirection: "row",
    marginBottom: 30,
  },
  twigContainer: {
    width: 300,
    height: 250,
    flexDirection: "row",
  },
  twigOne: {
    position: "absolute",
    left: -10,
    top: 50,
    width: 210,
    height: 200,
    transform: [{ rotate: "150deg" }],
  },
  twigTwo: {
    position: "absolute",
    right: -10,
    top: 0,
    width: 210,
    height: 200,
    transform: [{ rotate: "340deg" }],
  },
  orangeContainer: {
    width: 200,
    height: 150,
    flexDirection: "row",
  },
  orangeOne: {
    position: "absolute",
    left: 15,
    top: -30,
    width: 200,
    height: 150,
    transform: [{ rotate: "-10deg" }],
  },
  orangeTwo: {
    position: "absolute",
    right: 15,
    top: 0,
    width: 200,
    height: 150,
    transform: [{ rotate: "330deg" }],
  },
  basilContainer: {
    width: 300,
    height: 220,
    flexDirection: "row",
  },
  basilOne: {
    position: "absolute",
    left: 30,
    top: -20,
    width: 130,
    height: 130,
    transform: [{ rotate: "200deg" }],
  },
  basilTwo: {
    position: "absolute",
    right: 15,
    top: -10,
    width: 130,
    height: 130,
    transform: [{ rotate: "0deg" }],
  },
  basilThree: {
    position: "absolute",
    left: -10,
    top: 65,
    width: 120,
    height: 120,
    transform: [{ rotate: "15deg" }],
  },
  basilFour: {
    position: "absolute",
    right: 85,
    top: 75,
    width: 120,
    height: 120,
    transform: [{ rotate: "130deg" }],
  },
  webBrands: {
    flex: 1,
    justifyContent: "space-around",
    flexDirection: "row",
    marginRight: 80,
    marginTop: 100,
  },
  imageGrid: {
    flexDirection: "row",
  },
});

export default Aland;
